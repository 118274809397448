export const campusTableData = {
    keys: ["code", "name", "display_name", "consultancy", "client", "sector", "comments", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Campus Code",
            class: "",
            searchKey: "campus.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campuses",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Campus Name",
            class: "reg-name",
            searchKey: "campus.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campuses",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "name"
        },
        display_name: {
            isVisible: true,
            label: "Display Name",
            class: "reg-name",
            searchKey: "campus.display_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campuses",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "client-wid",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        sector: {
            isVisible: true,
            label: "Sector",
            class: "",
            searchKey: "sectors.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "sector",
            commonSearchKey: "sectors",
            commonSearchObjectKey: "name"
        },
        users: {
            isVisible: true,
            label: "Consultancy Users",
            class: "",
            searchKey: "users.name",
            type: "array",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "assigned_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "name"
        },
        client_users: {
            isVisible: true,
            label: "Client Users",
            class: "",
            searchKey: "client_users.name",
            type: "array",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "client_users",
            commonSearchKey: "client_users",
            commonSearchObjectKey: "name"
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "campus.comments",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campuses",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "comments"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "campus.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campuses",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "campus.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campuses",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const logbookTableData = {
    keys: ["code", "name", "display_name", "color", "text_color", "line", "order", "is_active", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Logbook Code",
            class: "",
            searchKey: "logbooks.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "code"
        },
        is_active: {
            isVisible: true,
            label: "Is Active?",
            class: "",
            searchKey: "logbooks.is_active",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "is_active"
        },
        name: {
            isVisible: true,
            label: "Logbook Name",
            class: "reg-name",
            searchKey: "logbooks.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "name"
        },
        display_name: {
            isVisible: true,
            label: "Display Name",
            class: "reg-name",
            searchKey: "logbooks.display_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "display_name"
        },
        // consultancy: {
        //     isVisible: true,
        //     label: "Consultancy",
        //     class: "",
        //     searchKey: "consultancies.name",
        //     type: "object",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: true,
        //     getListTable: "consultancies",
        //     commonSearchKey: "consultancies",
        //     commonSearchObjectKey: "name"
        // },
        // client: {
        //     isVisible: true,
        //     label: "Client",
        //     class: "client-wid",
        //     searchKey: "clients.name",
        //     type: "object",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: true,
        //     getListTable: "client",
        //     commonSearchKey: "clients",
        //     commonSearchObjectKey: "name"
        // },
        color: {
            isVisible: true,
            label: "Color",
            class: "",
            searchKey: "logbooks.color",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "color"
        },
        text_color: {
            isVisible: true,
            label: "Text Color",
            class: "",
            searchKey: "logbooks.text_color",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "text_color"
        },
        line: {
            isVisible: true,
            label: "Line",
            class: "",
            searchKey: "logbooks.line",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "line"
        },
        order: {
            isVisible: true,
            label: "Order",
            class: "",
            searchKey: "logbooks.order",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "order"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "logbooks.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "logbooks.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const sectorTableData = {
    keys: ["code", "name", "display_name", "consultancy", "client", "comments", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Sector Code",
            class: "",
            searchKey: "sectors.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sectors",
            commonSearchKey: "sectors",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Sector Name",
            class: "reg-name",
            searchKey: "sectors.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sectors",
            commonSearchKey: "sectors",
            commonSearchObjectKey: "name"
        },
        display_name: {
            isVisible: true,
            label: "Display Name",
            class: "reg-name",
            searchKey: "sectors.display_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sectors",
            commonSearchKey: "sectors",
            commonSearchObjectKey: "display_name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "client-wid",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        users: {
            isVisible: true,
            label: "Consultancy Users",
            class: "",
            searchKey: "users.name",
            type: "array",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancy_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "name"
        },
        client_users: {
            isVisible: true,
            label: "Client Users",
            class: "",
            searchKey: "client_users.name",
            type: "array",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "client_users",
            commonSearchKey: "client_users",
            commonSearchObjectKey: "name"
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "sectors.comments",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sectors",
            commonSearchKey: "sectors",
            commonSearchObjectKey: "comments"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "sectors.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sectors",
            commonSearchKey: "sectors",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "sectors.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sectors",
            commonSearchKey: "sectors",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const buildingTypeTableData = {
    keys: ["code", "name", "description", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Code",
            class: "reg-name",
            searchKey: "building_types.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_types",
            commonSearchKey: "building_types",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Name",
            class: "reg-name",
            searchKey: "building_types.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_types",
            commonSearchKey: "building_types",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "building_types.description",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_types",
            commonSearchKey: "building_types",
            commonSearchObjectKey: "description"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "building_types.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_types",
            commonSearchKey: "building_types",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "building_types.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_types",
            commonSearchKey: "building_types",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const notificationTableData = {
    keys: ["body", "recipient_name", "read_at", "seen_at", "created_at"],
    config: {
        body: {
            isVisible: true,
            label: "Message",
            class: "",
            searchKey: "notifications.body",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "notifications",
            commonSearchKey: "notifications",
            commonSearchObjectKey: "body"
        },
        recipient_name: {
            isVisible: true,
            label: "Recipient",
            class: "",
            searchKey: "notifications.recipient_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "notifications",
            commonSearchKey: "notifications",
            commonSearchObjectKey: "recipient_name"
        },
        seen_at: {
            isVisible: true,
            label: "Seen At",
            class: "",
            searchKey: "notifications.seen_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "notifications",
            commonSearchKey: "notifications",
            commonSearchObjectKey: "seen_at"
        },
        read_at: {
            isVisible: true,
            label: "Read At",
            class: "",
            searchKey: "notifications.read_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "notifications",
            commonSearchKey: "notifications",
            commonSearchObjectKey: "read_at"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "notifications.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "notifications",
            commonSearchKey: "notifications",
            commonSearchObjectKey: "created_at"
        }
    },
    data: []
};

export const buildingTableData = {
    keys: [
        "code",
        "name",
        "consultancy",
        "client",
        "sector",
        "campus",
        "deeming_agency",
        "building_type",
        "display_name",
        "description",
        "number",
        // "users",
        // "client_users",
        "area",
        "cost",
        "enterprise_index",
        "ownership",
        "ownership_type",
        "use",
        "manager",
        "year",
        "ministry",
        "street",
        "city",
        "state",
        "zip_code",
        "country",
        "comments",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Building Code",
            class: "",
            searchKey: "buildings.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Building Name",
            class: "",
            searchKey: "buildings.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "name"
        },
        display_name: {
            isVisible: true,
            label: "Display Name",
            class: "",
            searchKey: "buildings.display_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "name"
        },
        building_type: {
            isVisible: true,
            label: "Building Type",
            class: "",
            searchKey: "building_types.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "building_type",
            commonSearchKey: "building_types",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "client-wid",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        sector: {
            isVisible: true,
            label: "Sector",
            class: "",
            searchKey: "sectors.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "sector",
            commonSearchKey: "sectors",
            commonSearchObjectKey: "name"
        },
        campus: {
            isVisible: true,
            label: "Campus",
            class: "",
            searchKey: "campus.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "campus",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "name"
        },
        deeming_agency: {
            isVisible: true,
            label: "Deeming Agency",
            class: "",
            searchKey: "deeming_agencies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "deeming_agency",
            commonSearchKey: "deeming_agencies",
            commonSearchObjectKey: "name"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "buildings.description",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "description"
        },
        number: {
            isVisible: true,
            label: "Building Number",
            class: "",
            searchKey: "buildings.number",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "number"
        },
        projects: {
            isVisible: true,
            label: "Associated Projects",
            class: "",
            searchKey: "projects.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "name"
        },
        users: {
            isVisible: true,
            label: "Consultancy Users",
            class: "cons-user",
            searchKey: "users.name",
            type: "array",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "assigned_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "name"
        },
        client_users: {
            isVisible: true,
            label: "Client Users",
            class: "",
            searchKey: "client_users.name",
            type: "array",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "client_users",
            commonSearchKey: "client_users",
            commonSearchObjectKey: "name"
        },
        area: {
            isVisible: true,
            label: "Area (Sq Ft)",
            class: "",
            searchKey: "buildings.area",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "area"
        },
        cost: {
            isVisible: true,
            label: "Cost",
            class: "",
            searchKey: "buildings.cost",
            type: "money",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "cost"
        },
        enterprise_index: {
            isVisible: true,
            label: "Enterprise Index",
            class: "",
            searchKey: "buildings.enterprise_index",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "enterprise_index"
        },
        ownership: {
            isVisible: true,
            label: "Ownership",
            class: "clent",
            searchKey: "buildings.ownership",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "ownership"
        },
        ownership_type: {
            isVisible: true,
            label: "Ownership Type",
            class: "",
            searchKey: "buildings.ownership_type",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "ownership_type"
        },
        use: {
            isVisible: true,
            label: "Use",
            class: "",
            searchKey: "buildings.use",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "use"
        },
        manager: {
            isVisible: true,
            label: "Manager",
            class: "",
            searchKey: "buildings.manager",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "manager"
        },
        year: {
            isVisible: true,
            label: "Year Built",
            class: "",
            searchKey: "buildings.year",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "year"
        },
        ministry: {
            isVisible: true,
            label: "Ministry",
            class: "",
            searchKey: "buildings.ministry",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "ministry"
        },
        street: {
            isVisible: true,
            label: "Street",
            class: "",
            searchKey: "buildings.street",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "street"
        },
        city: {
            isVisible: true,
            label: "City",
            class: "",
            searchKey: "buildings.city",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "city"
        },
        state: {
            isVisible: true,
            label: "State",
            class: "",
            searchKey: "buildings.state",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "state"
        },
        zip_code: {
            isVisible: true,
            label: "ZipCode",
            class: "",
            searchKey: "buildings.zip_code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "zip_code"
        },
        country: {
            isVisible: true,
            label: "Country",
            class: "",
            searchKey: "buildings.country",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "country"
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "buildings.comments",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "comments"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "buildings.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "buildings.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const clientTableData = {
    keys: [
        "code",
        "name",
        "consultancy",
        "lock_total_devices",
        "modify_next_due_date",
        "request_email_recipt",
        "cmms_url",
        "comments",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Client Code",
            class: "",
            searchKey: "clients.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Client Name",
            class: "",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "code"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        display_blinking_red_plus: {
            isVisible: true,
            label: "Display Blinking Red Plus",
            class: "",
            searchKey: "clients.display_blinking_red_plus",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "display_blinking_red_plus"
        },
        ep_name: {
            isVisible: true,
            label: "Ep Name",
            class: "",
            searchKey: "clients.ep_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "ep_name"
        },
        lock_total_devices: {
            isVisible: true,
            label: "Lock Total Devices",
            class: "",
            searchKey: "clients.lock_total_devices",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "lock_total_devices"
        },
        modify_next_due_date: {
            isVisible: true,
            label: "Modify Next Due Date",
            class: "",
            searchKey: "clients.modify_next_due_date",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "modify_next_due_date"
        },
        request_email_recipt: {
            isVisible: true,
            label: "Request Email Receipt",
            class: "",
            searchKey: "clients.request_email_recipt",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "request_email_recipt"
        },
        trailing_view_current_month: {
            isVisible: true,
            label: "Trailing View Current Month",
            class: "",
            searchKey: "clients.trailing_view_current_month",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "trailing_view_current_month"
        },
        use_threshold_for_quarterly: {
            isVisible: true,
            label: "Use Threshold For Quarterly",
            class: "",
            searchKey: "clients.use_threshold_for_quarterly",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "use_threshold_for_quarterly"
        },
        cmms_url: {
            isVisible: true,
            label: "Cmms Url",
            class: "",
            searchKey: "clients.cmms_url",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "cmms_url"
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "clients.comments",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "comments"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "clients.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "clients.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const userTableData = {
    keys: [
        "code",
        "name",
        "first_name",
        "last_name",
        "printed_name",
        "email",
        "consultancy",
        "role",
        "client",
        "permission",
        // "group",
        // "buildings",
        // "projects",
        "title",
        // "credentials",
        "department",
        "work_phone",
        "cell_phone",
        "building_name",
        // "floor",
        "room_number",
        "room_name",
        "city",
        "location",
        "state",
        "zip_code",
        "address",
        "emergency_contact_no",
        "emergency_contact_name",
        "notes",
        "last_seen_at",
        // "is_active",
        // "is_blocked",
        // "last_sign_in_at",
        // "last_sign_out_at",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Code",
            class: "reg-name",
            searchKey: "users.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "User Name",
            class: "reg-name",
            searchKey: "users.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "name"
        },
        first_name: {
            isVisible: true,
            label: "First Name",
            class: "reg-name",
            searchKey: "users.first_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "first_name"
        },
        last_name: {
            isVisible: true,
            label: "Last Name",
            class: "reg-name",
            searchKey: "users.last_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_name"
        },
        printed_name: {
            isVisible: true,
            label: "Printed Name",
            class: "reg-name",
            searchKey: "users.printed_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "printed_name"
        },
        email: {
            isVisible: true,
            label: "Email",
            class: "",
            searchKey: "users.email",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "email"
        },
        last_seen_at: {
            isVisible: true,
            label: "Last Seen",
            class: "",
            searchKey: "users.last_seen_at",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_seen_at"
        },
        role: {
            isVisible: true,
            label: "Role",
            class: "",
            searchKey: "roles.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "role",
            commonSearchKey: "roles",
            commonSearchObjectKey: "name"
        },
        permission: {
            isVisible: true,
            label: "User Permission",
            class: "",
            searchKey: "permissions.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "permission",
            commonSearchKey: "permissions",
            commonSearchObjectKey: "name"
        },
        // buildings: {
        //     isVisible: true,
        //     label: "Buildings",
        //     class: "",
        //     searchKey: "buildings.name",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: false,
        //     getListTable: "buildings",
        //     commonSearchKey: "buildings",
        //     commonSearchObjectKey: "name"
        // },
        group: {
            isVisible: true,
            label: "Group",
            class: "",
            searchKey: "groups.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "groups",
            commonSearchKey: "groups",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        // projects: {
        //     isVisible: true,
        //     label: "Associated Projects",
        //     class: "",
        //     searchKey: "projects.name",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: false,
        //     getListTable: "projects",
        //     commonSearchKey: "projects",
        //     commonSearchObjectKey: "name"
        // },
        title: {
            isVisible: true,
            label: "Title",
            class: "",
            searchKey: "users.title",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "title"
        },
        work_phone: {
            isVisible: true,
            label: "Work Phone",
            class: "reg-name",
            searchKey: "users.work_phone",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "work_phone"
        },
        cell_phone: {
            isVisible: true,
            label: "Cell Phone",
            class: "reg-name",
            searchKey: "users.cell_phone",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "cell_phone"
        },
        building_name: {
            isVisible: true,
            label: "Building Name",
            class: "reg-name",
            searchKey: "users.building_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "building_name"
        },
        floor: {
            isVisible: true,
            label: "Floor",
            class: "reg-name",
            searchKey: "users.floor",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "floor"
        },
        room_number: {
            isVisible: true,
            label: "Room Number",
            class: "reg-name",
            searchKey: "users.room_number",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "room_number"
        },
        room_name: {
            isVisible: true,
            label: "Room Name",
            class: "reg-name",
            searchKey: "users.room_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "room_name"
        },
        emergency_contact_no: {
            isVisible: true,
            label: "Emergency Contact Number",
            class: "reg-name",
            searchKey: "users.emergency_contact_no",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "emergency_contact_no"
        },
        emergency_contact_name: {
            isVisible: true,
            label: "Emergency Contact Name",
            class: "reg-name",
            searchKey: "users.emergency_contact_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "emergency_contact_name"
        },
        notes: {
            isVisible: true,
            label: "Notes",
            class: "reg-name",
            searchKey: "users.notes",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "notes"
        },
        department: {
            isVisible: true,
            label: "Department",
            class: "reg-name",
            searchKey: "users.department",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "department"
        },
        credentials: {
            isVisible: true,
            label: "Credentials",
            class: "reg-name",
            searchKey: "users.credentials",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "credentials"
        },
        city: {
            isVisible: true,
            label: "City",
            class: "reg-name",
            searchKey: "users.city",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "city"
        },
        last_sign_in_at: {
            isVisible: true,
            label: "Last Sign in At",
            class: "reg-name",
            searchKey: "users.last_sign_in_at",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_sign_in_at"
        },
        last_sign_out_at: {
            isVisible: true,
            label: "Last Sign out At",
            class: "reg-name",
            searchKey: "users.last_sign_out_at",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_sign_out_at"
        },
        location: {
            isVisible: true,
            label: "Location",
            class: "reg-name",
            searchKey: "users.location",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "location"
        },
        state: {
            isVisible: true,
            label: "State",
            class: "reg-name",
            searchKey: "users.state",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "state"
        },
        zip_code: {
            isVisible: true,
            label: "Zip Code",
            class: "reg-name",
            searchKey: "users.zip_code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "zip_code"
        },
        address: {
            isVisible: true,
            label: "Address",
            class: "reg-name",
            searchKey: "users.address",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "address"
        },
        is_active: {
            isVisible: true,
            label: "Is Active",
            class: "reg-name",
            searchKey: "users.is_active",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "is_active"
        },
        is_blocked: {
            isVisible: true,
            label: "Is Blocked",
            class: "reg-name",
            searchKey: "users.is_blocked",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "is_blocked"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "users.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "users.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const userPermissionTableData = {
    keys: ["name", "consultancy", "display_order", "created_at", "updated_at"],
    config: {
        name: {
            isVisible: true,
            label: "Permission Name",
            class: "reg-name",
            searchKey: "permissions.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "permissions",
            commonSearchKey: "permissions",
            commonSearchObjectKey: "name"
        },
        display_order: {
            isVisible: true,
            label: "Display Order",
            class: "reg-name",
            searchKey: "permissions.display_order",
            type: "float",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "permissions",
            commonSearchKey: "permissions",
            commonSearchObjectKey: "display_order"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "reg-name",
            searchKey: "permissions.first_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "permissions",
            commonSearchKey: "permissions",
            commonSearchObjectKey: "consultancy"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "permissions.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "permissions",
            commonSearchKey: "permissions",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "permissions.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "permissions",
            commonSearchKey: "permissions",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const templateTableData = {
    keys: ["name", "display_order", "created_at", "updated_at"],
    config: {
        name: {
            isVisible: true,
            label: "Template Name",
            class: "reg-name",
            searchKey: "permissions.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "permissions",
            commonSearchKey: "permissions",
            commonSearchObjectKey: "name"
        },
        display_order: {
            isVisible: true,
            label: "Display Order",
            class: "reg-name",
            searchKey: "permissions.display_order",
            type: "float",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "permissions",
            commonSearchKey: "permissions",
            commonSearchObjectKey: "display_order"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "permissions.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "permissions",
            commonSearchKey: "permissions",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "permissions.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "permissions",
            commonSearchKey: "permissions",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const consultancyTableData = {
    keys: ["code", "name", "comments", "image", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Consultancy Code",
            class: "",
            searchKey: "consultancies.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Consultancy Name",
            class: "reg-name",
            searchKey: "consultancies.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "consultancies.comments",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "comments"
        },
        image: {
            isVisible: false,
            label: "Image",
            class: "",
            searchKey: "consultancies.image",
            type: "image",
            hasWildCardSearch: false,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "image"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "consultancies.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "consultancies.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const deemingAgencyTableData = {
    keys: ["code", "name", "display_order", "standard_prefix", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Deeming Agency Code",
            class: "",
            searchKey: "deeming_agencies.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "deeming_agencies",
            commonSearchKey: "deeming_agencies",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Deeming Agency Name",
            class: "reg-name",
            searchKey: "deeming_agencies.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "deeming_agencies",
            commonSearchKey: "deeming_agencies",
            commonSearchObjectKey: "name"
        },
        display_order: {
            isVisible: true,
            label: "Display Order",
            class: "reg-name",
            searchKey: "deeming_agencies.display_order",
            type: "float",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "deeming_agencies",
            commonSearchKey: "deeming_agencies",
            commonSearchObjectKey: "display_order"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "client-wid",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        standard_prefix: {
            isVisible: true,
            label: "Standard Prefix",
            class: "",
            searchKey: "deeming_agencies.standard_prefix",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "deeming_agencies",
            commonSearchKey: "deeming_agenciess",
            commonSearchObjectKey: "standard_prefix"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "deeming_agencies.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "deeming_agencies",
            commonSearchKey: "deeming_agencies",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "deeming_agencies.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "deeming_agencies",
            commonSearchKey: "deeming_agencies",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const activityCalenderTableData = {
    keys: [
        "sl_no",
        "display_order",
        "deeming_agency",
        "year",
        "standard",
        "client",
        "sector",
        "campus",
        "building",
        "activity_description",
        "default_total_devices",
        "test_frequency",
        "code_reference",
        "procedure_id",
        "date_last_performed",
        "next_due_date"
    ],
    config: {
        display_order: {
            isVisible: false,
            label: "DO",
            class: "ref",
            searchKey: "schedules.display_order",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 0
        },
        deeming_agency: {
            isVisible: true,
            label: "DA",
            class: "ref",
            searchKey: "deeming_agencies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "deeming_agency",
            commonSearchKey: "deeming_agencies",
            commonSearchObjectKey: "name",
            item: 1
        },
        year: {
            isVisible: true,
            label: "Year",
            class: "year",
            searchKey: "schedules.year",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 2
        },
        standard: {
            isVisible: true,
            label: "Std",
            class: "ref",
            searchKey: "schedules.standard",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 3
        },
        sector: {
            isVisible: true,
            label: "Sector",
            class: "region",
            searchKey: "sectors.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 4
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "region",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 5
        },
        campus: {
            isVisible: true,
            label: "Campus",
            class: "site",
            searchKey: "campus.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 6
        },
        building: {
            isVisible: true,
            label: "Building",
            class: "build",
            searchKey: "buildings.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 7
        },
        activity_description: {
            isVisible: true,
            label: "Activity",
            class: "activity",
            searchKey: "schedules.activity_description",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 8
        },
        default_total_devices: {
            isVisible: true,
            label: "DC",
            class: "d-count",
            searchKey: "schedules.default_total_devices",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 9
        },
        test_frequency: {
            isVisible: true,
            label: "Test Frequecy",
            class: "t-freq",
            searchKey: "schedules.test_frequency",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 10
        },
        code_reference: {
            isVisible: true,
            label: "Code Reference",
            class: "code-ref",
            searchKey: "schedules.code_reference",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 11
        },
        procedure_id: {
            isVisible: false,
            label: "Procedure Id",
            class: "p-id",
            searchKey: "schedules.procedure_id",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 12
        },
        date_last_performed: {
            isVisible: true,
            label: "Last Performed",
            class: "l-perform",
            searchKey: "schedules.date_last_performed",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 13
        },
        next_due_date: {
            isVisible: true,
            label: "Next Due Date",
            class: "n-due",
            searchKey: "schedules.next_due_date",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 14
        },
        sl_no: {
            isVisible: true,
            label: "Sl No",
            class: "",
            searchKey: "",
            type: "number",
            hasWildCardSearch: false,
            hasSort: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 15
        }
    },
    data: []
};

export const activityCalenderTableCalenderData = {
    keys: [
        "asset",
        "date_last_performed",
        "standard",
        "activity_description",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ],
    config: {
        date_last_performed: {
            isVisible: false,
            label: "Last Performed",
            class: "l-perform",
            searchKey: "schedules.date_last_performed",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "schedules",
            commonSearchKey: "schedules",
            commonSearchObjectKey: "date_last_performed",
            item: 12
        },
        asset: {
            isVisible: true,
            label: "Asset",
            class: "",
            searchKey: "assets.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "name"
        },
        standard: {
            isVisible: true,
            label: "Std",
            class: "ref",
            searchKey: "schedules.standard",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "schedules",
            commonSearchKey: "schedules",
            commonSearchObjectKey: "standard",
            item: 1
        },
        activity_description: {
            isVisible: true,
            label: "Activity",
            class: "activity",
            searchKey: "schedules.activity_description",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 5
        },
        Jan: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Jan"
        },
        Feb: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Feb"
        },
        Mar: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Mar"
        },
        Apr: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Apr"
        },
        May: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "May"
        },
        Jun: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Jun"
        },
        Jul: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Jul"
        },
        Aug: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Aug"
        },
        Sep: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Sep"
        },
        Oct: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Oct"
        },
        Nov: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Nov"
        },
        Dec: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Dec"
        }
    },
    data: []
};

export const consultancyLogbookTableData = {
    keys: ["code", "name", "display_name", "color", "text_color", "line", "order", "is_active", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Logbook Code",
            class: "",
            searchKey: "consultancy_logbooks.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "code"
        },
        is_active: {
            isVisible: true,
            label: "Is Active?",
            class: "",
            searchKey: "consultancy_logbooks.is_active",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "is_active"
        },
        name: {
            isVisible: true,
            label: "Logbook Name",
            class: "reg-name",
            searchKey: "consultancy_logbooks.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "name"
        },
        display_name: {
            isVisible: true,
            label: "Display Name",
            class: "reg-name",
            searchKey: "consultancy_logbooks.display_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "display_name"
        },
        // consultancy: {
        //     isVisible: true,
        //     label: "Consultancy",
        //     class: "",
        //     searchKey: "consultancies.name",
        //     type: "object",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: true,
        //     getListTable: "consultancies",
        //     commonSearchKey: "consultancies",
        //     commonSearchObjectKey: "name"
        // },
        // client: {
        //     isVisible: true,
        //     label: "Client",
        //     class: "client-wid",
        //     searchKey: "clients.name",
        //     type: "object",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: true,
        //     getListTable: "client",
        //     commonSearchKey: "clients",
        //     commonSearchObjectKey: "name"
        // },
        color: {
            isVisible: true,
            label: "Color",
            class: "",
            searchKey: "consultancy_logbooks.color",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "color"
        },
        text_color: {
            isVisible: true,
            label: "Text Color",
            class: "",
            searchKey: "consultancy_logbooks.text_color",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "text_color"
        },
        line: {
            isVisible: true,
            label: "Line",
            class: "",
            searchKey: "consultancy_logbooks.line",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "line"
        },
        order: {
            isVisible: true,
            label: "Order",
            class: "",
            searchKey: "consultancy_logbooks.order",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "order"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "consultancy_logbooks.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "consultancy_logbooks.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const clientLogbookTableData = {
    keys: ["code", "name", "display_name", "color", "text_color", "line", "order", "is_active", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Logbook Code",
            class: "",
            searchKey: "client_logbooks.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "client_logbooks",
            commonSearchKey: "client_logbooks",
            commonSearchObjectKey: "code"
        },
        is_active: {
            isVisible: true,
            label: "Is Active?",
            class: "",
            searchKey: "client_logbooks.is_active",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "client_logbooks",
            commonSearchKey: "client_logbooks",
            commonSearchObjectKey: "is_active"
        },
        name: {
            isVisible: true,
            label: "Logbook Name",
            class: "reg-name",
            searchKey: "client_logbooks.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "client_logbooks",
            commonSearchKey: "client_logbooks",
            commonSearchObjectKey: "name"
        },
        display_name: {
            isVisible: true,
            label: "Display Name",
            class: "reg-name",
            searchKey: "client_logbooks.display_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "client_logbooks",
            commonSearchKey: "client_logbooks",
            commonSearchObjectKey: "display_name"
        },
        // consultancy: {
        //     isVisible: true,
        //     label: "Consultancy",
        //     class: "",
        //     searchKey: "consultancies.name",
        //     type: "object",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: true,
        //     getListTable: "consultancies",
        //     commonSearchKey: "consultancies",
        //     commonSearchObjectKey: "name"
        // },
        // client: {
        //     isVisible: true,
        //     label: "Client",
        //     class: "client-wid",
        //     searchKey: "clients.name",
        //     type: "object",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: true,
        //     getListTable: "client",
        //     commonSearchKey: "clients",
        //     commonSearchObjectKey: "name"
        // },
        color: {
            isVisible: true,
            label: "Color",
            class: "",
            searchKey: "client_logbooks.color",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "client_logbooks",
            commonSearchKey: "client_logbooks",
            commonSearchObjectKey: "color"
        },
        text_color: {
            isVisible: true,
            label: "Text Color",
            class: "",
            searchKey: "client_logbooks.text_color",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "client_logbooks",
            commonSearchKey: "client_logbooks",
            commonSearchObjectKey: "text_color"
        },
        line: {
            isVisible: true,
            label: "Line",
            class: "",
            searchKey: "client_logbooks.line",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "client_logbooks",
            commonSearchKey: "client_logbooks",
            commonSearchObjectKey: "line"
        },
        order: {
            isVisible: true,
            label: "Order",
            class: "",
            searchKey: "client_logbooks.order",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "client_logbooks",
            commonSearchKey: "client_logbooks",
            commonSearchObjectKey: "order"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "client_logbooks.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "client_logbooks",
            commonSearchKey: "client_logbooks",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "client_logbooks.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "client_logbooks",
            commonSearchKey: "client_logbooks",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const buildingLogbookTableData = {
    keys: ["name", "client", "consultancy", "sector", "campus", "is_active", "created_at", "updated_at"],
    config: {
        // code: {
        //     isVisible: true,
        //     label: "Logbook Code",
        //     class: "",
        //     searchKey: "building_logbooks.code",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: false,
        //     getListTable: "building_logbooks",
        //     commonSearchKey: "building_logbooks",
        //     commonSearchObjectKey: "code"
        // },
        is_active: {
            isVisible: true,
            label: "Is Active?",
            class: "",
            searchKey: "building_logbooks.is_active",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_logbooks",
            commonSearchKey: "building_logbooks",
            commonSearchObjectKey: "is_active"
        },
        name: {
            isVisible: true,
            label: "Logbook",
            class: "reg-name",
            searchKey: "building_logbooks.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_logbooks",
            commonSearchKey: "building_logbooks",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "client-wid",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        // display_name: {
        //     isVisible: true,
        //     label: "Display Name",
        //     class: "reg-name",
        //     searchKey: "building_logbooks.display_name",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: false,
        //     getListTable: "building_logbooks",
        //     commonSearchKey: "building_logbooks",
        //     commonSearchObjectKey: "display_name"
        // },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        sector: {
            isVisible: true,
            label: "Sector",
            class: "",
            searchKey: "sectors.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "sector",
            commonSearchKey: "sectors",
            commonSearchObjectKey: "name"
        },
        campus: {
            isVisible: true,
            label: "Campus",
            class: "region-wid",
            searchKey: "campus.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "campuses",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "name"
        },
        // client: {
        //     isVisible: true,
        //     label: "Client",
        //     class: "client-wid",
        //     searchKey: "clients.name",
        //     type: "object",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: true,
        //     getListTable: "client",
        //     commonSearchKey: "clients",
        //     commonSearchObjectKey: "name"
        // },
        // color: {
        //     isVisible: true,
        //     label: "Color",
        //     class: "",
        //     searchKey: "building_logbooks.color",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: false,
        //     getListTable: "building_logbooks",
        //     commonSearchKey: "building_logbooks",
        //     commonSearchObjectKey: "color"
        // },
        // text_color: {
        //     isVisible: true,
        //     label: "Text Color",
        //     class: "",
        //     searchKey: "building_logbooks.text_color",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: false,
        //     getListTable: "building_logbooks",
        //     commonSearchKey: "building_logbooks",
        //     commonSearchObjectKey: "text_color"
        // },
        // line: {
        //     isVisible: true,
        //     label: "Line",
        //     class: "",
        //     searchKey: "building_logbooks.line",
        //     type: "number",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: false,
        //     getListTable: "building_logbooks",
        //     commonSearchKey: "building_logbooks",
        //     commonSearchObjectKey: "line"
        // },
        // order: {
        //     isVisible: true,
        //     label: "Order",
        //     class: "",
        //     searchKey: "building_logbooks.order",
        //     type: "number",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: false,
        //     getListTable: "building_logbooks",
        //     commonSearchKey: "building_logbooks",
        //     commonSearchObjectKey: "order"
        // },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "building_logbooks.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_logbooks",
            commonSearchKey: "building_logbooks",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "building_logbooks.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_logbooks",
            commonSearchKey: "building_logbooks",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const activityCalenderAllTableData = {
    keys: [
        "sl_no",
        "display_order",
        "deeming_agency",
        "year",
        "standard",
        "building_logbook",
        "client",
        "sector",
        "campus",
        "building",
        "activity_description",
        "default_total_devices",
        "test_frequency",
        "code_reference_tooltip",
        "procedure_id",
        "date_last_performed",
        "next_due_date"
    ],
    config: {
        display_order: {
            isVisible: true,
            label: "DO",
            class: "ref",
            searchKey: "schedules.display_order",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 0
        },
        deeming_agency: {
            isVisible: true,
            label: "DA",
            class: "ref",
            searchKey: "deeming_agencies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "deeming_agency",
            commonSearchKey: "deeming_agencies",
            commonSearchObjectKey: "name",
            item: 1
        },
        year: {
            isVisible: true,
            label: "Year",
            class: "ref",
            searchKey: "schedules.year",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 2
        },
        standard: {
            isVisible: true,
            label: "Std",
            class: "ref",
            searchKey: "schedules.standard",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 3
        },
        building_logbook: {
            isVisible: true,
            label: "Logbook",
            class: "region",
            searchKey: "building_logbooks.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 4
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "region",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 5
        },
        sector: {
            isVisible: true,
            label: "Sector",
            class: "region",
            searchKey: "sectors.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 6
        },
        campus: {
            isVisible: true,
            label: "Campus",
            class: "site",
            searchKey: "campus.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 7
        },
        building: {
            isVisible: true,
            label: "Building",
            class: "build",
            searchKey: "buildings.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 8
        },
        activity_description: {
            isVisible: true,
            label: "Activity",
            class: "activity",
            searchKey: "schedules.activity_description",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 9
        },
        default_total_devices: {
            isVisible: true,
            label: "DC",
            class: "d-count",
            searchKey: "schedules.default_total_devices",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 10
        },
        test_frequency: {
            isVisible: true,
            label: "Test Frequecy",
            class: "t-freq",
            searchKey: "schedules.test_frequency",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 11
        },
        code_reference_tooltip: {
            isVisible: true,
            label: "Code Reference",
            class: "code-ref",
            searchKey: "schedules.code_reference_tooltip",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 12
        },
        procedure_id: {
            isVisible: true,
            label: "Procedure Id",
            class: "p-id",
            searchKey: "schedules.procedure_id",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 13
        },
        date_last_performed: {
            isVisible: true,
            label: "Last Performed",
            class: "l-perform",
            searchKey: "schedules.date_last_performed",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 14
        },
        next_due_date: {
            isVisible: true,
            label: "Next Due Date",
            class: "n-due",
            searchKey: "schedules.next_due_date",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 15
        },
        sl_no: {
            isVisible: false,
            label: "Sl No",
            class: "",
            searchKey: "",
            type: "number",
            hasWildCardSearch: false,
            hasSort: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 16
        }
    },
    data: []
};

export const reportTableData = {
    keys: [
        "building_logbook",
        "client",
        "sector",
        "campus",
        "building",
        "parent",
        "asset",
        "edit_form",
        "year",
        "deeming_agency",
        "activity_description",
        "code_reference",
        "next_due_date",
        "actual_date",
        "threshold_start",
        "threshold_end",
        "test_frequency",
        "standard",
        "date_last_performed",
        "status",
        "performed_by",
        "default_total_devices",
        "total_devices",
        "number_pass",
        "number_fail",
        "number_corrected",
        "ilsm"
        // "tw_enabled"
    ],
    config: {
        date_last_performed: {
            isVisible: true,
            label: "Last Performed",
            class: "",
            searchKey: "schedules.date_last_performed",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        deeming_agency: {
            isVisible: true,
            label: "Deeming Agency",
            class: "",
            searchKey: "deeming_agencies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "deeming_agency",
            commonSearchKey: "deeming_agencies",
            commonSearchObjectKey: "name"
        },
        asset: {
            isVisible: true,
            label: "Asset",
            class: "",
            searchKey: "assets.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "asset",
            commonSearchKey: "assets",
            commonSearchObjectKey: "name"
        },
        edit_form: {
            isVisible: true,
            label: "Event Form",
            class: "",
            searchKey: "schedules.edit_form",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        standard: {
            isVisible: true,
            label: "Standard",
            class: "",
            searchKey: "schedules.standard",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        test_frequency: {
            isVisible: true,
            label: "Test Frequency",
            class: "",
            searchKey: "schedules.test_frequency",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        parent: {
            isVisible: true,
            label: "Parent",
            class: "",
            searchKey: "schedules.parent",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        building_logbook: {
            isVisible: true,
            label: "Logbook",
            class: "",
            searchKey: "building_logbooks.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        sector: {
            isVisible: true,
            label: "Sector",
            class: "",
            searchKey: "sectors.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        campus: {
            isVisible: true,
            label: "Campus",
            class: "",
            searchKey: "campus.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        building: {
            isVisible: true,
            label: "Building",
            class: "",
            searchKey: "buildings.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        threshold_start: {
            isVisible: true,
            label: "Threshold Start",
            class: "",
            searchKey: "survey_dates.date - schedules.completion_threshold",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        threshold_end: {
            isVisible: true,
            label: "Threshold End",
            class: "",
            searchKey: "survey_dates.date + schedules.completion_threshold",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        year: {
            isVisible: true,
            label: "Survey Year",
            class: "",
            searchKey: "schedules.year",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        activity_description: {
            isVisible: true,
            label: "Activity",
            class: "",
            searchKey: "schedules.activity_description",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        code_reference: {
            isVisible: true,
            label: "Code Reference",
            class: "",
            searchKey: "schedules.code_reference",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        next_due_date: {
            isVisible: true,
            label: "Due Date",
            class: "",
            searchKey: "survey_dates.date",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        actual_date: {
            isVisible: true,
            label: "Actual Date",
            class: "",
            searchKey: "surveys.actual_date",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        status: {
            isVisible: true,
            label: "Status",
            class: "",
            searchKey: "survey_dates.status",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        performed_by: {
            isVisible: true,
            label: "Performed By",
            class: "",
            searchKey: "surveys.performed_by",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        total_devices: {
            isVisible: true,
            label: "Total Devices",
            class: "",
            searchKey: "surveys.total_devices",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        default_total_devices: {
            isVisible: true,
            label: "Master Inventory  Total Devices",
            class: "",
            searchKey: "schedules.default_total_devices",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        number_pass: {
            isVisible: true,
            label: "Passed",
            class: "",
            searchKey: "surveys.number_pass",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        number_fail: {
            isVisible: true,
            label: "Failed",
            class: "",
            searchKey: "surveys.number_fail",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        number_corrected: {
            isVisible: true,
            label: "Corrected",
            class: "",
            searchKey: "surveys.number_corrected",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        ilsm: {
            isVisible: true,
            label: "Ilsm",
            class: "",
            searchKey: "surveys.ilsm",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        tw_enabled: {
            isVisible: true,
            label: "TW Enabled",
            class: "",
            searchKey: "schedules.tw_enabled",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        }
    },
    data: []
};

export const activityConsultancyTableData = {
    keys: ["code", "name", "comments", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Consultancy Code",
            class: "",
            searchKey: "consultancies.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_activities",
            commonSearchKey: "consultancy_activities",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Consultancy Name",
            class: "reg-name",
            searchKey: "consultancies.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "consultancies.comments",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "comments"
        },
        image: {
            isVisible: false,
            label: "Image",
            class: "",
            searchKey: "consultancies.image",
            type: "image",
            hasWildCardSearch: false,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "image"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "consultancies.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "consultancies.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const logbookConsultancyTableData = {
    keys: ["code", "name", "comments", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Consultancy Code",
            class: "",
            searchKey: "consultancies.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_activities",
            commonSearchKey: "consultancy_activities",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Consultancy Name",
            class: "reg-name",
            searchKey: "consultancies.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "consultancies.comments",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "comments"
        },
        image: {
            isVisible: false,
            label: "Image",
            class: "",
            searchKey: "consultancies.image",
            type: "image",
            hasWildCardSearch: false,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "image"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "consultancies.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "consultancies.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const activityClientTableData = {
    keys: [
        "code",
        "name",
        "consultancy",
        "lock_total_devices",
        "modify_next_due_date",
        "request_email_recipt",
        "cmms_url",
        "comments",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Client Code",
            class: "",
            searchKey: "clients.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Client Name",
            class: "",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "code"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        display_blinking_red_plus: {
            isVisible: true,
            label: "Display Blinking Red Plus",
            class: "",
            searchKey: "clients.display_blinking_red_plus",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "display_blinking_red_plus"
        },
        ep_name: {
            isVisible: true,
            label: "Ep Name",
            class: "",
            searchKey: "clients.ep_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "ep_name"
        },
        lock_total_devices: {
            isVisible: true,
            label: "Lock Total Devices",
            class: "",
            searchKey: "clients.lock_total_devices",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "lock_total_devices"
        },
        modify_next_due_date: {
            isVisible: true,
            label: "Modify Next Due Date",
            class: "",
            searchKey: "clients.modify_next_due_date",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "modify_next_due_date"
        },
        request_email_recipt: {
            isVisible: true,
            label: "Request Email Receipt",
            class: "",
            searchKey: "clients.request_email_recipt",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "request_email_recipt"
        },
        trailing_view_current_month: {
            isVisible: true,
            label: "Trailing View Current Month",
            class: "",
            searchKey: "clients.trailing_view_current_month",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "trailing_view_current_month"
        },
        use_threshold_for_quarterly: {
            isVisible: true,
            label: "Use Threshold For Quarterly",
            class: "",
            searchKey: "clients.use_threshold_for_quarterly",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "use_threshold_for_quarterly"
        },
        cmms_url: {
            isVisible: true,
            label: "Cmms Url",
            class: "",
            searchKey: "clients.cmms_url",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "cmms_url"
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "clients.comments",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "comments"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "clients.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "clients.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};
export const logbookClientTableData = {
    keys: [
        "code",
        "name",
        "consultancy",
        "lock_total_devices",
        "modify_next_due_date",
        "request_email_recipt",
        "cmms_url",
        "comments",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Client Code",
            class: "",
            searchKey: "clients.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Client Name",
            class: "",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "code"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        display_blinking_red_plus: {
            isVisible: true,
            label: "Display Blinking Red Plus",
            class: "",
            searchKey: "clients.display_blinking_red_plus",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "display_blinking_red_plus"
        },
        ep_name: {
            isVisible: true,
            label: "Ep Name",
            class: "",
            searchKey: "clients.ep_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "ep_name"
        },
        lock_total_devices: {
            isVisible: true,
            label: "Lock Total Devices",
            class: "",
            searchKey: "clients.lock_total_devices",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "lock_total_devices"
        },
        modify_next_due_date: {
            isVisible: true,
            label: "Modify Next Due Date",
            class: "",
            searchKey: "clients.modify_next_due_date",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "modify_next_due_date"
        },
        request_email_recipt: {
            isVisible: true,
            label: "Request Email Receipt",
            class: "",
            searchKey: "clients.request_email_recipt",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "request_email_recipt"
        },
        trailing_view_current_month: {
            isVisible: true,
            label: "Trailing View Current Month",
            class: "",
            searchKey: "clients.trailing_view_current_month",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "trailing_view_current_month"
        },
        use_threshold_for_quarterly: {
            isVisible: true,
            label: "Use Threshold For Quarterly",
            class: "",
            searchKey: "clients.use_threshold_for_quarterly",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "use_threshold_for_quarterly"
        },
        cmms_url: {
            isVisible: true,
            label: "Cmms Url",
            class: "",
            searchKey: "clients.cmms_url",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "cmms_url"
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "clients.comments",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "comments"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "clients.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "clients.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const activityBuildingTableData = {
    keys: [
        "code",
        "name",
        "consultancy",
        "client",
        "sector",
        "campus",
        "deeming_agency",
        "display_name",
        "description",
        "number",
        "area",
        "cost",
        "enterprise_index",
        "ownership",
        "ownership_type",
        "use",
        "manager",
        "year",
        "ministry",
        "street",
        "city",
        "state",
        "zip_code",
        "country",
        "comments",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Building Code",
            class: "",
            searchKey: "buildings.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Building Name",
            class: "",
            searchKey: "buildings.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "name"
        },
        display_name: {
            isVisible: true,
            label: "Display Name",
            class: "",
            searchKey: "buildings.display_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "name"
        },
        building_type: {
            isVisible: true,
            label: "Type",
            class: "",
            searchKey: "main_building_types.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "main_building_type",
            commonSearchKey: "main_building_types",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "client-wid",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        sector: {
            isVisible: true,
            label: "Sector",
            class: "",
            searchKey: "sectors.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "sector",
            commonSearchKey: "sectors",
            commonSearchObjectKey: "name"
        },
        campus: {
            isVisible: true,
            label: "Campus",
            class: "",
            searchKey: "campus.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "campus",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "name"
        },
        deeming_agency: {
            isVisible: true,
            label: "Deeming Agency",
            class: "",
            searchKey: "deeming_agencies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "deeming_agency",
            commonSearchKey: "deeming_agencies",
            commonSearchObjectKey: "name"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "buildings.description",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "description"
        },
        number: {
            isVisible: true,
            label: "Building Number",
            class: "",
            searchKey: "buildings.number",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "number"
        },
        projects: {
            isVisible: true,
            label: "Associated Projects",
            class: "",
            searchKey: "projects.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "name"
        },
        // users: {
        //     isVisible: true,
        //     label: "Consultancy Users",
        //     class: "cons-user",
        //     searchKey: "users.name",
        //     type: "array",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: true,
        //     getListTable: "assigned_users",
        //     commonSearchKey: "users",
        //     commonSearchObjectKey: "name"
        // },
        // client_users: {
        //     isVisible: true,
        //     label: "Client Users",
        //     class: "",
        //     searchKey: "client_users.name",
        //     type: "array",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: true,
        //     getListTable: "client_users",
        //     commonSearchKey: "client_users",
        //     commonSearchObjectKey: "name"
        // },
        area: {
            isVisible: true,
            label: "Area (Sq Ft)",
            class: "",
            searchKey: "buildings.area",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "area"
        },
        cost: {
            isVisible: true,
            label: "Cost",
            class: "",
            searchKey: "buildings.cost",
            type: "money",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "cost"
        },
        enterprise_index: {
            isVisible: true,
            label: "Enterprise Index",
            class: "",
            searchKey: "buildings.enterprise_index",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "enterprise_index"
        },
        ownership: {
            isVisible: true,
            label: "Ownership",
            class: "clent",
            searchKey: "buildings.ownership",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "ownership"
        },
        ownership_type: {
            isVisible: true,
            label: "Ownership Type",
            class: "",
            searchKey: "buildings.ownership_type",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "ownership_type"
        },
        use: {
            isVisible: true,
            label: "Use",
            class: "",
            searchKey: "buildings.use",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "use"
        },
        manager: {
            isVisible: true,
            label: "Manager",
            class: "",
            searchKey: "buildings.manager",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "manager"
        },
        year: {
            isVisible: true,
            label: "Year Built",
            class: "",
            searchKey: "buildings.year",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "year"
        },
        ministry: {
            isVisible: true,
            label: "Ministry",
            class: "",
            searchKey: "buildings.ministry",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "ministry"
        },
        street: {
            isVisible: true,
            label: "Street",
            class: "",
            searchKey: "buildings.street",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "street"
        },
        city: {
            isVisible: true,
            label: "City",
            class: "",
            searchKey: "buildings.city",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "city"
        },
        state: {
            isVisible: true,
            label: "State",
            class: "",
            searchKey: "buildings.state",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "state"
        },
        zip_code: {
            isVisible: true,
            label: "ZipCode",
            class: "",
            searchKey: "buildings.zip_code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "zip_code"
        },
        country: {
            isVisible: true,
            label: "Country",
            class: "",
            searchKey: "buildings.country",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "country"
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "buildings.comments",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "comments"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "buildings.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "buildings.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const frquencyTableData = {
    keys: [
        "name",
        "performance_window",
        "window",
        "recurrence",
        "interval",
        "interval_type",
        "test_frequency",
        "completion_threshold",
        "email_threshold",
        "created_at",
        "updated_at"
    ],
    config: {
        name: {
            isVisible: true,
            label: "Frequency Name",
            class: "reg-name",
            searchKey: "frequencies.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "name"
        },
        interval: {
            isVisible: true,
            label: "Interval",
            class: "",
            searchKey: "frequencies.interval",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "interval"
        },
        interval_type: {
            isVisible: true,
            label: "Interval Type",
            class: "",
            searchKey: "frequencies.interval_type",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "interval_type"
        },
        performance_window: {
            isVisible: true,
            label: "Performance Window",
            class: "",
            searchKey: "frequencies.performance_window",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "performance_window"
        },
        email_threshold: {
            isVisible: true,
            label: "Email Threshold",
            class: "",
            searchKey: "frequencies.email_threshold",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "email_threshold"
        },
        recurrence: {
            isVisible: true,
            label: "Recurrence in a Year",
            class: "",
            searchKey: "frequencies.recurrence",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "recurrence"
        },
        completion_threshold: {
            isVisible: true,
            label: "Completion Threshold",
            class: "",
            searchKey: "frequencies.completion_threshold",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "completion_threshold"
        },
        test_frequency: {
            isVisible: true,
            label: "Test Frequency",
            class: "",
            searchKey: "frequencies.test_frequency",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "test_frequency"
        },
        window: {
            isVisible: true,
            label: "Window",
            class: "",
            searchKey: "frequencies.window",
            type: "window",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "window"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "frequencies.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "frequencies.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const deemingAgencyFrequencyTableData = {
    keys: [
        "name",
        "performance_window",
        "window",
        "recurrence",
        "interval",
        "interval_type",
        "test_frequency",
        "completion_threshold",
        "email_threshold",
        "is_active",
        "created_at",
        "updated_at"
    ],
    config: {
        name: {
            isVisible: true,
            label: "Frequency Name",
            class: "reg-name",
            searchKey: "deeming_agency_frequencies.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "name"
        },
        interval: {
            isVisible: true,
            label: "Interval",
            class: "",
            searchKey: "deeming_agency_frequencies.interval",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "interval"
        },
        interval_type: {
            isVisible: true,
            label: "Interval Type",
            class: "",
            searchKey: "deeming_agency_frequencies.interval_type",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "interval_type"
        },
        performance_window: {
            isVisible: true,
            label: "Performance Window",
            class: "",
            searchKey: "deeming_agency_frequencies.performance_window",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "performance_window"
        },
        email_threshold: {
            isVisible: true,
            label: "Email Threshold",
            class: "",
            searchKey: "deeming_agency_frequencies.email_threshold",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "email_threshold"
        },
        recurrence: {
            isVisible: true,
            label: "Recurrence in a Year",
            class: "",
            searchKey: "deeming_agency_frequencies.recurrence",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "recurrence"
        },
        completion_threshold: {
            isVisible: true,
            label: "Completion Threshold",
            class: "",
            searchKey: "deeming_agency_frequencies.completion_threshold",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "completion_threshold"
        },
        test_frequency: {
            isVisible: true,
            label: "Test Frequency",
            class: "",
            searchKey: "deeming_agency_frequencies.test_frequency",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "test_frequency"
        },
        window: {
            isVisible: true,
            label: "Window",
            class: "",
            searchKey: "deeming_agency_frequencies.window",
            type: "window",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "window"
        },
        is_active: {
            isVisible: true,
            label: "Is Active?",
            class: "",
            searchKey: "deeming_agency_frequencies.is_active",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "is_active"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "deeming_agency_frequencies.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "deeming_agency_frequencies.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "frequencies",
            commonSearchKey: "frequencies",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const consultancyUserTableData = {
    keys: [
        "code",
        "name",
        "first_name",
        "last_name",
        "printed_name",
        "email",
        "consultancy",
        "role",
        "title",
        "department",
        "work_phone",
        "cell_phone",
        "building_name",
        "room_number",
        "room_name",
        "city",
        "location",
        "state",
        "zip_code",
        "address",
        "emergency_contact_no",
        "emergency_contact_name",
        "notes",
        "last_seen_at",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Code",
            class: "reg-name",
            searchKey: "users.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "User Name",
            class: "reg-name",
            searchKey: "users.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "name"
        },
        first_name: {
            isVisible: true,
            label: "First Name",
            class: "reg-name",
            searchKey: "users.first_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "first_name"
        },
        last_name: {
            isVisible: true,
            label: "Last Name",
            class: "reg-name",
            searchKey: "users.last_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_name"
        },
        printed_name: {
            isVisible: true,
            label: "Printed Name",
            class: "reg-name",
            searchKey: "users.printed_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "printed_name"
        },
        email: {
            isVisible: true,
            label: "Email",
            class: "",
            searchKey: "users.email",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "email"
        },
        last_seen_at: {
            isVisible: true,
            label: "Last Seen",
            class: "",
            searchKey: "users.last_seen_at",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_seen_at"
        },
        role: {
            isVisible: true,
            label: "Role",
            class: "",
            searchKey: "roles.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "role",
            commonSearchKey: "roles",
            commonSearchObjectKey: "name"
        },
        // buildings: {
        //     isVisible: true,
        //     label: "Buildings",
        //     class: "",
        //     searchKey: "buildings.name",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: false,
        //     getListTable: "buildings",
        //     commonSearchKey: "buildings",
        //     commonSearchObjectKey: "name"
        // },
        group: {
            isVisible: true,
            label: "Group",
            class: "",
            searchKey: "groups.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "groups",
            commonSearchKey: "groups",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        // projects: {
        //     isVisible: true,
        //     label: "Associated Projects",
        //     class: "",
        //     searchKey: "projects.name",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: false,
        //     getListTable: "projects",
        //     commonSearchKey: "projects",
        //     commonSearchObjectKey: "name"
        // },
        title: {
            isVisible: true,
            label: "Title",
            class: "",
            searchKey: "users.title",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "title"
        },
        work_phone: {
            isVisible: true,
            label: "Work Phone",
            class: "reg-name",
            searchKey: "users.work_phone",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "work_phone"
        },
        cell_phone: {
            isVisible: true,
            label: "Cell Phone",
            class: "reg-name",
            searchKey: "users.cell_phone",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "cell_phone"
        },
        building_name: {
            isVisible: true,
            label: "Building Name",
            class: "reg-name",
            searchKey: "users.building_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "building_name"
        },
        floor: {
            isVisible: true,
            label: "Floor",
            class: "reg-name",
            searchKey: "users.floor",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "floor"
        },
        room_number: {
            isVisible: true,
            label: "Room Number",
            class: "reg-name",
            searchKey: "users.room_number",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "room_number"
        },
        room_name: {
            isVisible: true,
            label: "Room Name",
            class: "reg-name",
            searchKey: "users.room_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "room_name"
        },
        emergency_contact_no: {
            isVisible: true,
            label: "Emergency Contact Number",
            class: "reg-name",
            searchKey: "users.emergency_contact_no",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "emergency_contact_no"
        },
        emergency_contact_name: {
            isVisible: true,
            label: "Emergency Contact Name",
            class: "reg-name",
            searchKey: "users.emergency_contact_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "emergency_contact_name"
        },
        notes: {
            isVisible: true,
            label: "Notes",
            class: "reg-name",
            searchKey: "users.notes",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "notes"
        },
        department: {
            isVisible: true,
            label: "Department",
            class: "reg-name",
            searchKey: "users.department",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "department"
        },
        credentials: {
            isVisible: true,
            label: "Credentials",
            class: "reg-name",
            searchKey: "users.credentials",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "credentials"
        },
        city: {
            isVisible: true,
            label: "City",
            class: "reg-name",
            searchKey: "users.city",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "city"
        },
        last_sign_in_at: {
            isVisible: true,
            label: "Last Sign in At",
            class: "reg-name",
            searchKey: "users.last_sign_in_at",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_sign_in_at"
        },
        last_sign_out_at: {
            isVisible: true,
            label: "Last Sign out At",
            class: "reg-name",
            searchKey: "users.last_sign_out_at",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_sign_out_at"
        },
        location: {
            isVisible: true,
            label: "Location",
            class: "reg-name",
            searchKey: "users.location",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "location"
        },
        state: {
            isVisible: true,
            label: "State",
            class: "reg-name",
            searchKey: "users.state",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "state"
        },
        zip_code: {
            isVisible: true,
            label: "Zip Code",
            class: "reg-name",
            searchKey: "users.zip_code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "zip_code"
        },
        address: {
            isVisible: true,
            label: "Address",
            class: "reg-name",
            searchKey: "users.address",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "address"
        },
        is_active: {
            isVisible: true,
            label: "Is Active",
            class: "reg-name",
            searchKey: "users.is_active",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "is_active"
        },
        is_blocked: {
            isVisible: true,
            label: "Is Blocked",
            class: "reg-name",
            searchKey: "users.is_blocked",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "is_blocked"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "users.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "users.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const sectotUserTableData = {
    keys: [
        "code",
        "name",
        "first_name",
        "last_name",
        "printed_name",
        "email",
        "consultancy",
        "role",
        "title",
        "department",
        "work_phone",
        "cell_phone",
        "building_name",
        "room_number",
        "room_name",
        "city",
        "location",
        "state",
        "zip_code",
        "address",
        "emergency_contact_no",
        "emergency_contact_name",
        "notes",
        "last_seen_at",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Code",
            class: "reg-name",
            searchKey: "users.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "User Name",
            class: "reg-name",
            searchKey: "users.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "name"
        },
        first_name: {
            isVisible: true,
            label: "First Name",
            class: "reg-name",
            searchKey: "users.first_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "first_name"
        },
        last_name: {
            isVisible: true,
            label: "Last Name",
            class: "reg-name",
            searchKey: "users.last_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_name"
        },
        printed_name: {
            isVisible: true,
            label: "Printed Name",
            class: "reg-name",
            searchKey: "users.printed_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "printed_name"
        },
        email: {
            isVisible: true,
            label: "Email",
            class: "",
            searchKey: "users.email",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "email"
        },
        last_seen_at: {
            isVisible: true,
            label: "Last Seen",
            class: "",
            searchKey: "users.last_seen_at",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_seen_at"
        },
        role: {
            isVisible: true,
            label: "Role",
            class: "",
            searchKey: "roles.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "role",
            commonSearchKey: "roles",
            commonSearchObjectKey: "name"
        },
        group: {
            isVisible: true,
            label: "Group",
            class: "",
            searchKey: "groups.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "groups",
            commonSearchKey: "groups",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        title: {
            isVisible: true,
            label: "Title",
            class: "",
            searchKey: "users.title",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "title"
        },
        work_phone: {
            isVisible: true,
            label: "Work Phone",
            class: "reg-name",
            searchKey: "users.work_phone",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "work_phone"
        },
        cell_phone: {
            isVisible: true,
            label: "Cell Phone",
            class: "reg-name",
            searchKey: "users.cell_phone",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "cell_phone"
        },
        building_name: {
            isVisible: true,
            label: "Building Name",
            class: "reg-name",
            searchKey: "users.building_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "building_name"
        },
        floor: {
            isVisible: true,
            label: "Floor",
            class: "reg-name",
            searchKey: "users.floor",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "floor"
        },
        room_number: {
            isVisible: true,
            label: "Room Number",
            class: "reg-name",
            searchKey: "users.room_number",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "room_number"
        },
        room_name: {
            isVisible: true,
            label: "Room Name",
            class: "reg-name",
            searchKey: "users.room_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "room_name"
        },
        emergency_contact_no: {
            isVisible: true,
            label: "Emergency Contact Number",
            class: "reg-name",
            searchKey: "users.emergency_contact_no",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "emergency_contact_no"
        },
        emergency_contact_name: {
            isVisible: true,
            label: "Emergency Contact Name",
            class: "reg-name",
            searchKey: "users.emergency_contact_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "emergency_contact_name"
        },
        notes: {
            isVisible: true,
            label: "Notes",
            class: "reg-name",
            searchKey: "users.notes",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "notes"
        },
        department: {
            isVisible: true,
            label: "Department",
            class: "reg-name",
            searchKey: "users.department",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "department"
        },
        credentials: {
            isVisible: true,
            label: "Credentials",
            class: "reg-name",
            searchKey: "users.credentials",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "credentials"
        },
        city: {
            isVisible: true,
            label: "City",
            class: "reg-name",
            searchKey: "users.city",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "city"
        },
        last_sign_in_at: {
            isVisible: true,
            label: "Last Sign in At",
            class: "reg-name",
            searchKey: "users.last_sign_in_at",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_sign_in_at"
        },
        last_sign_out_at: {
            isVisible: true,
            label: "Last Sign out At",
            class: "reg-name",
            searchKey: "users.last_sign_out_at",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_sign_out_at"
        },
        location: {
            isVisible: true,
            label: "Location",
            class: "reg-name",
            searchKey: "users.location",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "location"
        },
        state: {
            isVisible: true,
            label: "State",
            class: "reg-name",
            searchKey: "users.state",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "state"
        },
        zip_code: {
            isVisible: true,
            label: "Zip Code",
            class: "reg-name",
            searchKey: "users.zip_code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "zip_code"
        },
        address: {
            isVisible: true,
            label: "Address",
            class: "reg-name",
            searchKey: "users.address",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "address"
        },
        is_active: {
            isVisible: true,
            label: "Is Active",
            class: "reg-name",
            searchKey: "users.is_active",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "is_active"
        },
        is_blocked: {
            isVisible: true,
            label: "Is Blocked",
            class: "reg-name",
            searchKey: "users.is_blocked",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "is_blocked"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "users.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "users.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "sector_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const buildingUserTableData = {
    keys: [
        "code",
        "name",
        "first_name",
        "last_name",
        "printed_name",
        "email",
        "consultancy",
        "role",
        "title",
        "department",
        "work_phone",
        "cell_phone",
        "building_name",
        "room_number",
        "room_name",
        "city",
        "location",
        "state",
        "zip_code",
        "address",
        "emergency_contact_no",
        "emergency_contact_name",
        "notes",
        "last_seen_at",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Code",
            class: "reg-name",
            searchKey: "users.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "User Name",
            class: "reg-name",
            searchKey: "users.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "name"
        },
        first_name: {
            isVisible: true,
            label: "First Name",
            class: "reg-name",
            searchKey: "users.first_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "first_name"
        },
        last_name: {
            isVisible: true,
            label: "Last Name",
            class: "reg-name",
            searchKey: "users.last_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_name"
        },
        printed_name: {
            isVisible: true,
            label: "Printed Name",
            class: "reg-name",
            searchKey: "users.printed_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "printed_name"
        },
        email: {
            isVisible: true,
            label: "Email",
            class: "",
            searchKey: "users.email",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "email"
        },
        last_seen_at: {
            isVisible: true,
            label: "Last Seen",
            class: "",
            searchKey: "users.last_seen_at",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_seen_at"
        },
        role: {
            isVisible: true,
            label: "Role",
            class: "",
            searchKey: "roles.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "role",
            commonSearchKey: "roles",
            commonSearchObjectKey: "name"
        },
        group: {
            isVisible: true,
            label: "Group",
            class: "",
            searchKey: "groups.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "groups",
            commonSearchKey: "groups",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        title: {
            isVisible: true,
            label: "Title",
            class: "",
            searchKey: "users.title",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "title"
        },
        work_phone: {
            isVisible: true,
            label: "Work Phone",
            class: "reg-name",
            searchKey: "users.work_phone",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "work_phone"
        },
        cell_phone: {
            isVisible: true,
            label: "Cell Phone",
            class: "reg-name",
            searchKey: "users.cell_phone",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "cell_phone"
        },
        building_name: {
            isVisible: true,
            label: "Building Name",
            class: "reg-name",
            searchKey: "users.building_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "building_name"
        },
        floor: {
            isVisible: true,
            label: "Floor",
            class: "reg-name",
            searchKey: "users.floor",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "floor"
        },
        room_number: {
            isVisible: true,
            label: "Room Number",
            class: "reg-name",
            searchKey: "users.room_number",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "room_number"
        },
        room_name: {
            isVisible: true,
            label: "Room Name",
            class: "reg-name",
            searchKey: "users.room_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "room_name"
        },
        emergency_contact_no: {
            isVisible: true,
            label: "Emergency Contact Number",
            class: "reg-name",
            searchKey: "users.emergency_contact_no",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "emergency_contact_no"
        },
        emergency_contact_name: {
            isVisible: true,
            label: "Emergency Contact Name",
            class: "reg-name",
            searchKey: "users.emergency_contact_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "emergency_contact_name"
        },
        notes: {
            isVisible: true,
            label: "Notes",
            class: "reg-name",
            searchKey: "users.notes",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "notes"
        },
        department: {
            isVisible: true,
            label: "Department",
            class: "reg-name",
            searchKey: "users.department",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "department"
        },
        credentials: {
            isVisible: true,
            label: "Credentials",
            class: "reg-name",
            searchKey: "users.credentials",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "credentials"
        },
        city: {
            isVisible: true,
            label: "City",
            class: "reg-name",
            searchKey: "users.city",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "city"
        },
        last_sign_in_at: {
            isVisible: true,
            label: "Last Sign in At",
            class: "reg-name",
            searchKey: "users.last_sign_in_at",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_sign_in_at"
        },
        last_sign_out_at: {
            isVisible: true,
            label: "Last Sign out At",
            class: "reg-name",
            searchKey: "users.last_sign_out_at",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_sign_out_at"
        },
        location: {
            isVisible: true,
            label: "Location",
            class: "reg-name",
            searchKey: "users.location",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "location"
        },
        state: {
            isVisible: true,
            label: "State",
            class: "reg-name",
            searchKey: "users.state",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "state"
        },
        zip_code: {
            isVisible: true,
            label: "Zip Code",
            class: "reg-name",
            searchKey: "users.zip_code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "zip_code"
        },
        address: {
            isVisible: true,
            label: "Address",
            class: "reg-name",
            searchKey: "users.address",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "address"
        },
        is_active: {
            isVisible: true,
            label: "Is Active",
            class: "reg-name",
            searchKey: "users.is_active",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "is_active"
        },
        is_blocked: {
            isVisible: true,
            label: "Is Blocked",
            class: "reg-name",
            searchKey: "users.is_blocked",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "is_blocked"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "users.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "users.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "building_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const campusUserTableData = {
    keys: [
        "code",
        "name",
        "first_name",
        "last_name",
        "printed_name",
        "email",
        "consultancy",
        "role",
        "title",
        "department",
        "work_phone",
        "cell_phone",
        "building_name",
        "room_number",
        "room_name",
        "city",
        "location",
        "state",
        "zip_code",
        "address",
        "emergency_contact_no",
        "emergency_contact_name",
        "notes",
        "last_seen_at",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Code",
            class: "reg-name",
            searchKey: "users.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "User Name",
            class: "reg-name",
            searchKey: "users.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "name"
        },
        first_name: {
            isVisible: true,
            label: "First Name",
            class: "reg-name",
            searchKey: "users.first_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "first_name"
        },
        last_name: {
            isVisible: true,
            label: "Last Name",
            class: "reg-name",
            searchKey: "users.last_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_name"
        },
        printed_name: {
            isVisible: true,
            label: "Printed Name",
            class: "reg-name",
            searchKey: "users.printed_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "printed_name"
        },
        email: {
            isVisible: true,
            label: "Email",
            class: "",
            searchKey: "users.email",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "email"
        },
        last_seen_at: {
            isVisible: true,
            label: "Last Seen",
            class: "",
            searchKey: "users.last_seen_at",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_seen_at"
        },
        role: {
            isVisible: true,
            label: "Role",
            class: "",
            searchKey: "roles.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "role",
            commonSearchKey: "roles",
            commonSearchObjectKey: "name"
        },
        group: {
            isVisible: true,
            label: "Group",
            class: "",
            searchKey: "groups.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "groups",
            commonSearchKey: "groups",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        title: {
            isVisible: true,
            label: "Title",
            class: "",
            searchKey: "users.title",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "title"
        },
        work_phone: {
            isVisible: true,
            label: "Work Phone",
            class: "reg-name",
            searchKey: "users.work_phone",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "work_phone"
        },
        cell_phone: {
            isVisible: true,
            label: "Cell Phone",
            class: "reg-name",
            searchKey: "users.cell_phone",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "cell_phone"
        },
        building_name: {
            isVisible: true,
            label: "Building Name",
            class: "reg-name",
            searchKey: "users.building_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "building_name"
        },
        floor: {
            isVisible: true,
            label: "Floor",
            class: "reg-name",
            searchKey: "users.floor",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "floor"
        },
        room_number: {
            isVisible: true,
            label: "Room Number",
            class: "reg-name",
            searchKey: "users.room_number",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "room_number"
        },
        room_name: {
            isVisible: true,
            label: "Room Name",
            class: "reg-name",
            searchKey: "users.room_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "room_name"
        },
        emergency_contact_no: {
            isVisible: true,
            label: "Emergency Contact Number",
            class: "reg-name",
            searchKey: "users.emergency_contact_no",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "emergency_contact_no"
        },
        emergency_contact_name: {
            isVisible: true,
            label: "Emergency Contact Name",
            class: "reg-name",
            searchKey: "users.emergency_contact_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "emergency_contact_name"
        },
        notes: {
            isVisible: true,
            label: "Notes",
            class: "reg-name",
            searchKey: "users.notes",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "notes"
        },
        department: {
            isVisible: true,
            label: "Department",
            class: "reg-name",
            searchKey: "users.department",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "department"
        },
        credentials: {
            isVisible: true,
            label: "Credentials",
            class: "reg-name",
            searchKey: "users.credentials",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "credentials"
        },
        city: {
            isVisible: true,
            label: "City",
            class: "reg-name",
            searchKey: "users.city",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "city"
        },
        last_sign_in_at: {
            isVisible: true,
            label: "Last Sign in At",
            class: "reg-name",
            searchKey: "users.last_sign_in_at",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_sign_in_at"
        },
        last_sign_out_at: {
            isVisible: true,
            label: "Last Sign out At",
            class: "reg-name",
            searchKey: "users.last_sign_out_at",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_sign_out_at"
        },
        location: {
            isVisible: true,
            label: "Location",
            class: "reg-name",
            searchKey: "users.location",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "location"
        },
        state: {
            isVisible: true,
            label: "State",
            class: "reg-name",
            searchKey: "users.state",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "state"
        },
        zip_code: {
            isVisible: true,
            label: "Zip Code",
            class: "reg-name",
            searchKey: "users.zip_code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "zip_code"
        },
        address: {
            isVisible: true,
            label: "Address",
            class: "reg-name",
            searchKey: "users.address",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "address"
        },
        is_active: {
            isVisible: true,
            label: "Is Active",
            class: "reg-name",
            searchKey: "users.is_active",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "is_active"
        },
        is_blocked: {
            isVisible: true,
            label: "Is Blocked",
            class: "reg-name",
            searchKey: "users.is_blocked",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "is_blocked"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "users.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "users.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campus_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const userBuildingTableData = {
    keys: [
        "code",
        "name",
        "consultancy",
        "client",
        "sector",
        "campus",
        "deeming_agency",
        "building_type",
        "display_name",
        "description",
        "number",
        // "users",
        // "client_users",
        "area",
        "cost",
        "enterprise_index",
        "ownership",
        "ownership_type",
        "use",
        "manager",
        "year",
        "ministry",
        "street",
        "city",
        "state",
        "zip_code",
        "country",
        "comments",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Building Code",
            class: "",
            searchKey: "buildings.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Building Name",
            class: "",
            searchKey: "buildings.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "name"
        },
        display_name: {
            isVisible: true,
            label: "Display Name",
            class: "",
            searchKey: "buildings.display_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "name"
        },
        building_type: {
            isVisible: true,
            label: "Building Type",
            class: "",
            searchKey: "building_types.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "building_type",
            commonSearchKey: "building_types",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            // searchKey: "buildings.consultany",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "client-wid",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        sector: {
            isVisible: true,
            label: "Sector",
            class: "",
            searchKey: "sectors.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "sector",
            commonSearchKey: "sectors",
            commonSearchObjectKey: "name"
        },
        campus: {
            isVisible: true,
            label: "Campus",
            class: "",
            searchKey: "campus.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "campus",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "name"
        },
        deeming_agency: {
            isVisible: true,
            label: "Deeming Agency",
            class: "",
            searchKey: "deeming_agencies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "deeming_agency",
            commonSearchKey: "deeming_agencies",
            commonSearchObjectKey: "name"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "buildings.description",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "description"
        },
        number: {
            isVisible: true,
            label: "Building Number",
            class: "",
            searchKey: "buildings.number",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "number"
        },
        projects: {
            isVisible: true,
            label: "Associated Projects",
            class: "",
            searchKey: "projects.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "name"
        },
        users: {
            isVisible: true,
            label: "Consultancy Users",
            class: "cons-user",
            searchKey: "users.name",
            type: "array",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "assigned_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "name"
        },
        client_users: {
            isVisible: true,
            label: "Client Users",
            class: "",
            searchKey: "client_users.name",
            type: "array",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "client_users",
            commonSearchKey: "client_users",
            commonSearchObjectKey: "name"
        },
        area: {
            isVisible: true,
            label: "Area (Sq Ft)",
            class: "",
            searchKey: "buildings.area",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "area"
        },
        cost: {
            isVisible: true,
            label: "Cost",
            class: "",
            searchKey: "buildings.cost",
            type: "money",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "cost"
        },
        enterprise_index: {
            isVisible: true,
            label: "Enterprise Index",
            class: "",
            searchKey: "buildings.enterprise_index",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "enterprise_index"
        },
        ownership: {
            isVisible: true,
            label: "Ownership",
            class: "clent",
            searchKey: "buildings.ownership",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "ownership"
        },
        ownership_type: {
            isVisible: true,
            label: "Ownership Type",
            class: "",
            searchKey: "buildings.ownership_type",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "ownership_type"
        },
        use: {
            isVisible: true,
            label: "Use",
            class: "",
            searchKey: "buildings.use",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "use"
        },
        manager: {
            isVisible: true,
            label: "Manager",
            class: "",
            searchKey: "buildings.manager",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "manager"
        },
        year: {
            isVisible: true,
            label: "Year Built",
            class: "",
            searchKey: "buildings.year",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "year"
        },
        ministry: {
            isVisible: true,
            label: "Ministry",
            class: "",
            searchKey: "buildings.ministry",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "ministry"
        },
        street: {
            isVisible: true,
            label: "Street",
            class: "",
            searchKey: "buildings.street",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "street"
        },
        city: {
            isVisible: true,
            label: "City",
            class: "",
            searchKey: "buildings.city",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "city"
        },
        state: {
            isVisible: true,
            label: "State",
            class: "",
            searchKey: "buildings.state",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "state"
        },
        zip_code: {
            isVisible: true,
            label: "ZipCode",
            class: "",
            searchKey: "buildings.zip_code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "zip_code"
        },
        country: {
            isVisible: true,
            label: "Country",
            class: "",
            searchKey: "buildings.country",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "country"
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "buildings.comments",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "comments"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "building_users.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "building_users.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const userBuildingLogbookTableData = {
    keys: ["code", "name", "consultancy", "client", "sector", "campus", "building", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Logbook Code",
            class: "",
            searchKey: "logbooks.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "code"
        },
        is_active: {
            isVisible: true,
            label: "Is Active?",
            class: "",
            searchKey: "logbooks.is_active",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "is_active"
        },
        name: {
            isVisible: true,
            label: "Logbook Name",
            class: "reg-name",
            searchKey: "logbooks.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            // searchKey: "buildings.consultany",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "client-wid",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        sector: {
            isVisible: true,
            label: "Sector",
            class: "",
            searchKey: "sectors.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "sector",
            commonSearchKey: "sectors",
            commonSearchObjectKey: "name"
        },
        campus: {
            isVisible: true,
            label: "Campus",
            class: "",
            searchKey: "campus.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "campus",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "name"
        },
        building: {
            isVisible: true,
            label: "Building",
            class: "",
            searchKey: "building.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "building",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "name"
        },
        display_name: {
            isVisible: true,
            label: "Display Name",
            class: "reg-name",
            searchKey: "logbooks.display_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "display_name"
        },
        // consultancy: {
        //     isVisible: true,
        //     label: "Consultancy",
        //     class: "",
        //     searchKey: "consultancies.name",
        //     type: "object",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: true,
        //     getListTable: "consultancies",
        //     commonSearchKey: "consultancies",
        //     commonSearchObjectKey: "name"
        // },
        // client: {
        //     isVisible: true,
        //     label: "Client",
        //     class: "client-wid",
        //     searchKey: "clients.name",
        //     type: "object",
        //     hasWildCardSearch: true,
        //     hasSort: true,
        //     hasCommonSearch: true,
        //     getListTable: "client",
        //     commonSearchKey: "clients",
        //     commonSearchObjectKey: "name"
        // },
        color: {
            isVisible: true,
            label: "Color",
            class: "",
            searchKey: "logbooks.color",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "color"
        },
        text_color: {
            isVisible: true,
            label: "Text Color",
            class: "",
            searchKey: "logbooks.text_color",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "text_color"
        },
        line: {
            isVisible: true,
            label: "Line",
            class: "",
            searchKey: "logbooks.line",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "line"
        },
        order: {
            isVisible: true,
            label: "Order",
            class: "",
            searchKey: "logbooks.order",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "order"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "logbooks.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "logbooks.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const procedureTableData = {
    keys: ["code", "procedure_id", "description", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Code",
            class: "reg-name",
            searchKey: "procedures.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "procedures",
            commonSearchKey: "procedures",
            commonSearchObjectKey: "code"
        },
        procedure_id: {
            isVisible: true,
            label: "Procedure Id",
            class: "reg-name",
            searchKey: "procedures.procedure_id",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "procedures",
            commonSearchKey: "procedures",
            commonSearchObjectKey: "name"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "procedures.description",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "procedures",
            commonSearchKey: "procedures",
            commonSearchObjectKey: "description"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "procedures.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "procedures",
            commonSearchKey: "procedures",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "procedures.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "procedures",
            commonSearchKey: "procedures",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const smartReportTableData = {
    keys: [
        "building_logbook",
        "client",
        "sector",
        "campus",
        "building",
        "parent",
        "asset",
        "edit_form",
        "year",
        "deeming_agency",
        "activity_description",
        "code_reference",
        "next_due_date",
        "actual_date",
        "threshold_start",
        "threshold_end",
        "test_frequency",
        "standard",
        "date_last_performed",
        "status",
        "performed_by",
        "default_total_devices",
        "total_devices",
        "number_pass",
        "number_fail",
        "failure_percentage",
        "number_corrected",
        "ilsm"
        // "tw_enabled"
    ],
    config: {
        date_last_performed: {
            isVisible: true,
            label: "Last Performed",
            class: "",
            searchKey: "schedules.date_last_performed",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        deeming_agency: {
            isVisible: true,
            label: "Deeming Agency",
            class: "",
            searchKey: "deeming_agencies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "deeming_agency",
            commonSearchKey: "deeming_agencies",
            commonSearchObjectKey: "name"
        },
        standard: {
            isVisible: true,
            label: "Standard",
            class: "",
            searchKey: "schedules.standard",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        asset: {
            isVisible: true,
            label: "Asset",
            class: "",
            searchKey: "assets.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "asset",
            commonSearchKey: "assets",
            commonSearchObjectKey: "name"
        },
        edit_form: {
            isVisible: true,
            label: "Event Form",
            class: "",
            searchKey: "schedules.edit_form",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        parent: {
            isVisible: true,
            label: "Parent",
            class: "",
            searchKey: "schedules.parent",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        test_frequency: {
            isVisible: true,
            label: "Test Frequency",
            class: "",
            searchKey: "schedules.test_frequency",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        building_logbook: {
            isVisible: true,
            label: "Logbook",
            class: "",
            searchKey: "building_logbooks.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        sector: {
            isVisible: true,
            label: "Sector",
            class: "",
            searchKey: "sectors.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        campus: {
            isVisible: true,
            label: "Campus",
            class: "",
            searchKey: "campus.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        building: {
            isVisible: true,
            label: "Building",
            class: "",
            searchKey: "buildings.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        threshold_start: {
            isVisible: true,
            label: "Threshold Start",
            class: "",
            searchKey: "survey_dates.date - schedules.completion_threshold",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        threshold_end: {
            isVisible: true,
            label: "Threshold End",
            class: "",
            searchKey: "survey_dates.date + schedules.completion_threshold",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        year: {
            isVisible: true,
            label: "Survey Year",
            class: "",
            searchKey: "schedules.year",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        activity_description: {
            isVisible: true,
            label: "Activity",
            class: "",
            searchKey: "schedules.activity_description",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        code_reference: {
            isVisible: true,
            label: "Code Reference",
            class: "",
            searchKey: "schedules.code_reference",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        next_due_date: {
            isVisible: true,
            label: "Due Date",
            class: "",
            searchKey: "survey_dates.date",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        actual_date: {
            isVisible: true,
            label: "Actual Date",
            class: "",
            searchKey: "surveys.actual_date",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        status: {
            isVisible: true,
            label: "Status",
            class: "",
            searchKey: "survey_dates.status",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        performed_by: {
            isVisible: true,
            label: "Performed By",
            class: "",
            searchKey: "surveys.performed_by",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        total_devices: {
            isVisible: true,
            label: "Total Devices",
            class: "",
            searchKey: "surveys.total_devices",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        default_total_devices: {
            isVisible: true,
            label: "Master Inventory  Total Devices",
            class: "",
            searchKey: "schedules.default_total_devices",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        number_pass: {
            isVisible: true,
            label: "Passed",
            class: "",
            searchKey: "surveys.number_pass",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        number_fail: {
            isVisible: true,
            label: "Failed",
            class: "",
            searchKey: "surveys.number_fail",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        failure_percentage: {
            isVisible: true,
            label: "Failure Percentage",
            class: "",
            searchKey: "surveys.failure_percentage",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        number_corrected: {
            isVisible: true,
            label: "Corrected",
            class: "",
            searchKey: "surveys.number_corrected",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        ilsm: {
            isVisible: true,
            label: "Ilsm",
            class: "",
            searchKey: "surveys.ilsm",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        tw_enabled: {
            isVisible: true,
            label: "TW Enabled",
            class: "",
            searchKey: "schedules.tw_enabled",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        }
    },
    data: []
};

export const formTableData = {
    keys: ["code", "form_id", "description", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Code",
            class: "reg-name",
            searchKey: "forms.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "forms",
            commonSearchKey: "forms",
            commonSearchObjectKey: "code"
        },
        form_id: {
            isVisible: true,
            label: "Form Id",
            class: "reg-name",
            searchKey: "forms.procedure_id",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "forms",
            commonSearchKey: "forms",
            commonSearchObjectKey: "name"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "forms.description",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "forms",
            commonSearchKey: "forms",
            commonSearchObjectKey: "description"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "forms.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "forms",
            commonSearchKey: "forms",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "forms.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "forms",
            commonSearchKey: "forms",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const floorTableData = {
    keys: ["code", "name", "consultancy", "client", "sector", "campus", "building", "comment", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Code",
            class: "reg-name",
            searchKey: "floors.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "floors",
            commonSearchKey: "floors",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Name",
            class: "",
            searchKey: "floors.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "floors",
            commonSearchKey: "floors",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "client-wid",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        sector: {
            isVisible: true,
            label: "Sector",
            class: "",
            searchKey: "sectors.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "sector",
            commonSearchKey: "sectors",
            commonSearchObjectKey: "name"
        },
        campus: {
            isVisible: true,
            label: "Campus",
            class: "",
            searchKey: "campus.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "campus",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "name"
        },
        building: {
            isVisible: true,
            label: "Building",
            class: "",
            searchKey: "buildings.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "name"
        },
        comment: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "floors.comment",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "floors",
            commonSearchKey: "floors",
            commonSearchObjectKey: "comment"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "floors.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "floors",
            commonSearchKey: "floors",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "floors.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "floors",
            commonSearchKey: "floors",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const assetConditionTableData = {
    keys: ["code", "name", "description", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Code",
            class: "",
            searchKey: "asset_conditions.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "asset_conditions",
            commonSearchKey: "asset_conditions",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Name",
            class: "",
            searchKey: "asset_conditions.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "asset_conditions",
            commonSearchKey: "asset_conditions",
            commonSearchObjectKey: "name"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "asset_conditions.description",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "asset_conditions",
            commonSearchKey: "asset_conditions",
            commonSearchObjectKey: "description"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "asset_conditions.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "asset_conditions",
            commonSearchKey: "asset_conditions",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "asset_conditions.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "asset_conditions",
            commonSearchKey: "asset_conditions",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const assetTableData = {
    keys: [
        "asset_id",
        "name",
        "asset_type",
        "image",
        "consultancy",
        "client",
        "sector",
        "campus",
        "building",
        "floor",
        "room",
        "area_served",
        "make",
        "model_number",
        "serial_number",
        "size",
        "volts",
        "amps",
        "year_of_unit",
        "installed_year",
        "service_life",
        "useful_life_remaining",
        "crv",
        "asset_condition",
        "notes",
        "device_count",
        "is_active",
        "created_at",
        "updated_at"
    ],
    config: {
        asset_id: {
            isVisible: true,
            label: "Asset Id",
            class: "",
            searchKey: "assets.asset_id",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "asset_id"
        },
        name: {
            isVisible: true,
            label: "Asset Name",
            class: "",
            searchKey: "assets.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "name"
        },
        asset_type: {
            isVisible: true,
            label: "Asset Type",
            class: "",
            searchKey: "assets.asset_type",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "asset_type"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "client-wid",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        sector: {
            isVisible: true,
            label: "Sector",
            class: "",
            searchKey: "sectors.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "sector",
            commonSearchKey: "sectors",
            commonSearchObjectKey: "name"
        },
        campus: {
            isVisible: true,
            label: "Campus",
            class: "",
            searchKey: "campus.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "campus",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "name"
        },
        building: {
            isVisible: true,
            label: "Building",
            class: "",
            searchKey: "buildings.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "name"
        },
        floor: {
            isVisible: true,
            label: "Floor",
            class: "",
            searchKey: "floors.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "floors",
            commonSearchKey: "floors",
            commonSearchObjectKey: "name"
        },
        room: {
            isVisible: true,
            label: "Room",
            class: "",
            searchKey: "assets.room",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "room"
        },
        image: {
            isVisible: false,
            label: "Image",
            class: "",
            searchKey: "assets.image",
            type: "image",
            hasWildCardSearch: false,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "image"
        },
        area_served: {
            isVisible: true,
            label: "Area Served",
            class: "",
            searchKey: "assets.area_served",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "area_served"
        },
        make: {
            isVisible: true,
            label: "Make",
            class: "",
            searchKey: "assets.make",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "make"
        },
        model_number: {
            isVisible: true,
            label: "Model Number",
            class: "",
            searchKey: "assets.model_number",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "model_number"
        },
        serial_number: {
            isVisible: true,
            label: "Serial Number",
            class: "",
            searchKey: "assets.serial_number",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "serial_number"
        },
        size: {
            isVisible: true,
            label: "Size",
            class: "",
            searchKey: "assets.size",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "size"
        },
        volts: {
            isVisible: true,
            label: "Volts",
            class: "",
            searchKey: "assets.volts",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "volts"
        },
        amps: {
            isVisible: true,
            label: "Amps",
            class: "",
            searchKey: "assets.amps",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "amps"
        },
        year_of_unit: {
            isVisible: true,
            label: "Year of Unit",
            class: "",
            searchKey: "assets.year_of_unit",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "year_of_unit"
        },
        installed_year: {
            isVisible: true,
            label: "Installed Year",
            class: "",
            searchKey: "assets.installed_year",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "installed_year"
        },
        service_life: {
            isVisible: true,
            label: "Service Life",
            class: "",
            searchKey: "assets.service_life",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "service_life"
        },
        useful_life_remaining: {
            isVisible: true,
            label: "Useful Life Remaining",
            class: "",
            searchKey: "assets.useful_life_remaining",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "useful_life_remaining"
        },
        crv: {
            isVisible: true,
            label: "CRV",
            class: "",
            searchKey: "assets.crv",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "crv"
        },
        asset_condition: {
            isVisible: true,
            label: "Asset Condition",
            class: "",
            searchKey: "asset_conditions.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "asset_conditions",
            commonSearchKey: "asset_conditions",
            commonSearchObjectKey: "name"
        },
        notes: {
            isVisible: true,
            label: "Notes",
            class: "",
            searchKey: "assets.notes",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "notes"
        },
        device_count: {
            isVisible: true,
            label: "Device Count",
            class: "",
            searchKey: "assets.device_count",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "device_count"
        },
        is_active: {
            isVisible: true,
            label: "Is Active",
            class: "",
            searchKey: "assets.is_active",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "is_active"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "assets.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "assets.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};
