export const GET_ALL_LOGBOOKS_REQUEST = "GET_ALL_LOGBOOKS_REQUEST";
export const GET_ALL_LOGBOOKS_SUCCESS = "GET_ALL_LOGBOOKS_SUCCESS";
export const GET_ALL_LOGBOOKS_FAILURE = "GET_ALL_LOGBOOKS_FAILURE";

export const GET_SCHEDULES_BY_LOGBOOK_ID_REQUEST = "GET_SCHEDULES_BY_LOGBOOK_ID_REQUEST";
export const GET_SCHEDULES_BY_LOGBOOK_ID_SUCCESS = "GET_SCHEDULES_BY_LOGBOOK_ID_SUCCESS";
export const GET_SCHEDULES_BY_LOGBOOK_ID_FAILURE = "GET_SCHEDULES_BY_LOGBOOK_ID_FAILURE";

export const GET_TRAILING_SCHEDULES_BY_LOGBOOK_ID_REQUEST = "GET_TRAILING_SCHEDULES_BY_LOGBOOK_ID_REQUEST";
export const GET_TRAILING_SCHEDULES_BY_LOGBOOK_ID_SUCCESS = "GET_TRAILING_SCHEDULES_BY_LOGBOOK_ID_SUCCESS";
export const GET_TRAILING_SCHEDULES_BY_LOGBOOK_ID_FAILURE = "GET_TRAILING_SCHEDULES_BY_LOGBOOK_ID_FAILURE";

export const SET_ACTIVITY_TABLE_WIDTH_REQUEST = "SET_ACTIVITY_TABLE_WIDTH_REQUEST";
export const SET_ACTIVITY_TABLE_WIDTH_SUCCESS = "SET_ACTIVITY_TABLE_WIDTH_SUCCESS";
export const SET_ACTIVITY_TABLE_WIDTH_FAILURE = "SET_ACTIVITY_TABLE_WIDTH_FAILURE";

export const GET_ACTIVITY_TABLE_WIDTH_REQUEST = "GET_ACTIVITY_TABLE_WIDTH_REQUEST";
export const GET_ACTIVITY_TABLE_WIDTH_SUCCESS = "GET_ACTIVITY_TABLE_WIDTH_SUCCESS";
export const GET_ACTIVITY_TABLE_WIDTH_FAILURE = "GET_ACTIVITY_TABLE_WIDTH_FAILURE";

export const GET_SURVEY_DETAILS_REQUEST = "GET_SURVEY_DETAILS_REQUEST";
export const GET_SURVEY_DETAILS_SUCCESS = "GET_SURVEY_DETAILS_SUCCESS";
export const GET_SURVEY_DETAILS_FAILURE = "GET_SURVEY_DETAILS_FAILURE";

export const GET_LOGBOOK_DOCUMENTS_REQUEST = "GET_LOGBOOK_DOCUMENTS_REQUEST";
export const GET_LOGBOOK_DOCUMENTS_SUCCESS = "GET_LOGBOOK_DOCUMENTS_SUCCESS";
export const GET_LOGBOOK_DOCUMENTS_FAILURE = "GET_LOGBOOK_DOCUMENTS_FAILURE";

export const SAVE_ACTIVITY_EVENT_REQUEST = "SAVE_ACTIVITY_EVENT_REQUEST";
export const SAVE_ACTIVITY_EVENT_SUCCESS = "SAVE_ACTIVITY_EVENT_SUCCESS";
export const SAVE_ACTIVITY_EVENT_FAILURE = "SAVE_ACTIVITY_EVENT_FAILURE";

export const UPLOAD_DOCUMENTS_REQUEST = "UPLOAD_DOCUMENTS_REQUEST";
export const UPLOAD_DOCUMENTS_SUCCESS = "UPLOAD_DOCUMENTS_SUCCESS";
export const UPLOAD_DOCUMENTS_FAILURE = "UPLOAD_DOCUMENTS_FAILURE";

export const REMOVE_ATTACHMENT_REQUEST = "REMOVE_ATTACHMENT_REQUEST";
export const REMOVE_ATTACHMENT_SUCCESS = "REMOVE_ATTACHMENT_SUCCESS";
export const REMOVE_ATTACHMENT_FAILURE = "REMOVE_ATTACHMENT_FAILURE";

export const GET_ACTIVITY_EVENT_POPUP_DETAILS_REQUEST = "GET_ACTIVITY_EVENT_POPUP_DETAILS_REQUEST";
export const GET_ACTIVITY_EVENT_POPUP_DETAILS_SUCCESS = "GET_ACTIVITY_EVENT_POPUP_DETAILS_SUCCESS";
export const GET_ACTIVITY_EVENT_POPUP_DETAILS_FAILURE = "GET_ACTIVITY_EVENT_POPUP_DETAILS_FAILURE";

export const EXECUTE_ACTIVITY_EVENT_REQUEST = "EXECUTE_ACTIVITY_EVENT_REQUEST";
export const EXECUTE_ACTIVITY_EVENT_SUCCESS = "EXECUTE_ACTIVITY_EVENT_SUCCESS";
export const EXECUTE_ACTIVITY_EVENT_FAILURE = "EXECUTE_ACTIVITY_EVENT_FAILURE";

export const GET_ASSIGN_ACTIVITY_POPUP_DETAILS_REQUEST = "GET_ASSIGN_ACTIVITY_POPUP_DETAILS_REQUEST";
export const GET_ASSIGN_ACTIVITY_POPUP_DETAILS_SUCCESS = "GET_ASSIGN_ACTIVITY_POPUP_DETAILS_SUCCESS";
export const GET_ASSIGN_ACTIVITY_POPUP_DETAILS_FAILURE = "GET_ASSIGN_ACTIVITY_POPUP_DETAILS_FAILURE";

export const GET_CREATE_SURVEY_POPUP_DETAILS_REQUEST = "GET_CREATE_SURVEY_POPUP_DETAILS_REQUEST";
export const GET_CREATE_SURVEY_POPUP_DETAILS_SUCCESS = "GET_CREATE_SURVEY_POPUP_DETAILS_SUCCESS";
export const GET_CREATE_SURVEY_POPUP_DETAILS_FAILURE = "GET_CREATE_SURVEY_POPUP_DETAILS_FAILURE";

export const GET_CURRENT_ASSIGNMENTS_REQUEST = "GET_CURRENT_ASSIGNMENTS_REQUEST";
export const GET_CURRENT_ASSIGNMENTS_SUCCESS = "GET_CURRENT_ASSIGNMENTS_SUCCESS";
export const GET_CURRENT_ASSIGNMENTS_FAILURE = "GET_CURRENT_ASSIGNMENTS_FAILURE";

export const ASSIGN_ACTIVITY_TO_BUILDING_REQUEST = "ASSIGN_ACTIVITY_TO_BUILDING_REQUEST";
export const ASSIGN_ACTIVITY_TO_BUILDING_SUCCESS = "ASSIGN_ACTIVITY_TO_BUILDING_SUCCESS";
export const ASSIGN_ACTIVITY_TO_BUILDING_FAILURE = "ASSIGN_ACTIVITY_TO_BUILDING_FAILURE";

export const CREATE_SURVEY_REQUEST = "CREATE_SURVEY_REQUEST";
export const CREATE_SURVEY_SUCCESS = "CREATE_SURVEY_SUCCESS";
export const CREATE_SURVEY_FAILURE = "CREATE_SURVEY_FAILURE";

export const GET_ASSIGN_LOGBOOK_POPUP_DETAILS_REQUEST = "GET_ASSIGN_LOGBOOK_POPUP_DETAILS_REQUEST";
export const GET_ASSIGN_LOGBOOK_POPUP_DETAILS_SUCCESS = "GET_ASSIGN_LOGBOOK_POPUP_DETAILS_SUCCESS";
export const GET_ASSIGN_LOGBOOK_POPUP_DETAILS_FAILURE = "GET_ASSIGN_LOGBOOK_POPUP_DETAILS_FAILURE";

export const GET_CREATE_SURVEY_POPUP_DETAILS_FOR_BUILDING_REQUEST = "GET_CREATE_SURVEY_POPUP_DETAILS_FOR_BUILDING_REQUEST";
export const GET_CREATE_SURVEY_POPUP_DETAILS_FOR_BUILDING_SUCCESS = "GET_CREATE_SURVEY_POPUP_DETAILS_FOR_BUILDING_SUCCESS";
export const GET_CREATE_SURVEY_POPUP_DETAILS_FOR_BUILDING_FAILURE = "GET_CREATE_SURVEY_POPUP_DETAILS_FOR_BUILDING_FAILURE";

export const ASSIGN_LOGBOOK_TO_BUILDING_REQUEST = "ASSIGN_LOGBOOK_TO_BUILDING_REQUEST";
export const ASSIGN_LOGBOOK_TO_BUILDING_SUCCESS = "ASSIGN_LOGBOOK_TO_BUILDING_SUCCESS";
export const ASSIGN_LOGBOOK_TO_BUILDING_FAILURE = "ASSIGN_LOGBOOK_TO_BUILDING_FAILURE";

export const UPDATE_ACTIVITY_CALENDAR_ENTITY_PARAMS_SUCCESS = "UPDATE_ACTIVITY_CALENDAR_ENTITY_PARAMS_SUCCESS";
export const UPDATE_ACTIVITY_CALENDAR_ENTITY_PARAMS_FAILURE = "UPDATE_ACTIVITY_CALENDAR_ENTITY_PARAMS_FAILURE";

export const GET_ASSIGN_LOGBOOK_POPUP_FOR_CONSULTANCY_DETAILS_REQUEST = "GET_ASSIGN_LOGBOOK_POPUP_FOR_CONSULTANCY_DETAILS_REQUEST";
export const GET_ASSIGN_LOGBOOK_POPUP_FOR_CONSULTANCY_DETAILS_SUCCESS = "GET_ASSIGN_LOGBOOK_POPUP_FOR_CONSULTANCY_DETAILS_SUCCESS";
export const GET_ASSIGN_LOGBOOK_POPUP_FOR_CONSULTANCY_DETAILS_FAILURE = "GET_ASSIGN_LOGBOOK_POPUP_FOR_CONSULTANCY_DETAILS_FAILURE";

export const GET_ASSIGN_LOGBOOK_POPUP_FOR_CLIENT_DETAILS_REQUEST = "GET_ASSIGN_LOGBOOK_POPUP_FOR_CLIENT_DETAILS_REQUEST";
export const GET_ASSIGN_LOGBOOK_POPUP_FOR_CLIENT_DETAILS_SUCCESS = "GET_ASSIGN_LOGBOOK_POPUP_FOR_CLIENT_DETAILS_SUCCESS";
export const GET_ASSIGN_LOGBOOK_POPUP_FOR_CLIENT_DETAILS_FAILURE = "GET_ASSIGN_LOGBOOK_POPUP_FOR_CLIENT_DETAILS_FAILURE";

export const ASSIGN_LOGBOOK_TO_CONSULTANCY_REQUEST = "ASSIGN_LOGBOOK_TO_CONSULTANCY_REQUEST";
export const ASSIGN_LOGBOOK_TO_CONSULTANCY_SUCCESS = "ASSIGN_LOGBOOK_TO_CONSULTANCY_SUCCESS";
export const ASSIGN_LOGBOOK_TO_CONSULTANCY_FAILURE = "ASSIGN_LOGBOOK_TO_CONSULTANCY_FAILURE";

export const ASSIGN_LOGBOOK_TO_CLIENT_REQUEST = "ASSIGN_LOGBOOK_TO_CLIENT_REQUEST";
export const ASSIGN_LOGBOOK_TO_CLIENT_SUCCESS = "ASSIGN_LOGBOOK_TO_CLIENT_SUCCESS";
export const ASSIGN_LOGBOOK_TO_CLIENT_FAILURE = "ASSIGN_LOGBOOK_TO_CLIENT_FAILURE";

export const EXPORT_SCHEDULE_TABLE_REQUEST = "EXPORT_SCHEDULE_TABLE_REQUEST";
export const EXPORT_SCHEDULE_TABLE_SUCCESS = "EXPORT_SCHEDULE_TABLE_SUCCESS";
export const EXPORT_SCHEDULE_TABLE_FAILURE = "EXPORT_SCHEDULE_TABLE_FAILURE";

export const DELETE_SCHEDULE_BY_ID_REQUEST = "DELETE_SCHEDULE_BY_ID_REQUEST";
export const DELETE_SCHEDULE_BY_ID_SUCCESS = "DELETE_SCHEDULE_BY_ID_SUCCESS";
export const DELETE_SCHEDULE_BY_ID_FAILURE = "DELETE_SCHEDULE_BY_ID_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const UPDATE_ACTIVITY_CALENDAR_ENTITY_PARAMS_ANNUAL_SUCCESS = "UPDATE_ACTIVITY_CALENDAR_ENTITY_PARAMS_ANNUAL_SUCCESS";
export const UPDATE_ACTIVITY_CALENDAR_ENTITY_PARAMS_ANNUAL_FAILURE = "UPDATE_ACTIVITY_CALENDAR_ENTITY_PARAMS_ANNUAL_FAILURE";

export const GET_UNDO_NA_POPUP_DETAILS_REQUEST = "GET_UNDO_NA_POPUP_DETAILS_REQUEST";
export const GET_UNDO_NA_POPUP_DETAILS_SUCCESS = "GET_UNDO_NA_POPUP_DETAILS_SUCCESS";
export const GET_UNDO_NA_POPUP_DETAILS_FAILURE = "GET_UNDO_NA_POPUP_DETAILS_FAILURE";

export const UNDO_NA_REQUEST = "UNDO_NA_REQUEST";
export const UNDO_NA_SUCCESS = "UNDO_NA_SUCCESS";
export const UNDO_NA_FAILURE = "UNDO_NA_FAILURE";

export const GET_ASSIGN_ACTIVITY_POPUP_FOR_CONSULTANCY_DETAILS_REQUEST = "GET_ASSIGN_ACTIVITY_POPUP_FOR_CONSULTANCY_DETAILS_REQUEST";
export const GET_ASSIGN_ACTIVITY_POPUP_FOR_CONSULTANCY_DETAILS_SUCCESS = "GET_ASSIGN_ACTIVITY_POPUP_FOR_CONSULTANCY_DETAILS_SUCCESS";
export const GET_ASSIGN_ACTIVITY_POPUP_FOR_CONSULTANCY_DETAILS_FAILURE = "GET_ASSIGN_ACTIVITY_POPUP_FOR_CONSULTANCY_DETAILS_FAILURE";

export const ASSIGN_ACTIVITY_TO_CONSULTANCY_REQUEST = "ASSIGN_ACTIVITY_TO_CONSULTANCY_REQUEST";
export const ASSIGN_ACTIVITY_TO_CONSULTANCY_SUCCESS = "ASSIGN_ACTIVITY_TO_CONSULTANCY_SUCCESS";
export const ASSIGN_ACTIVITY_TO_CONSULTANCY_FAILURE = "ASSIGN_ACTIVITY_TO_CONSULTANCY_FAILURE";

export const GET_ASSIGN_ACTIVITY_POPUP_FOR_CLIENT_DETAILS_REQUEST = "GET_ASSIGN_ACTIVITY_POPUP_FOR_CLIENT_DETAILS_REQUEST";
export const GET_ASSIGN_ACTIVITY_POPUP_FOR_CLIENT_DETAILS_SUCCESS = "GET_ASSIGN_ACTIVITY_POPUP_FOR_CLIENT_DETAILS_SUCCESS";
export const GET_ASSIGN_ACTIVITY_POPUP_FOR_CLIENT_DETAILS_FAILURE = "GET_ASSIGN_ACTIVITY_POPUP_FOR_CLIENT_DETAILS_FAILURE";

export const ASSIGN_ACTIVITY_TO_CLIENT_REQUEST = "ASSIGN_ACTIVITY_TO_CLIENT_REQUEST";
export const ASSIGN_ACTIVITY_TO_CLIENT_SUCCESS = "ASSIGN_ACTIVITY_TO_CLIENT_SUCCESS";
export const ASSIGN_ACTIVITY_TO_CLIENT_FAILURE = "ASSIGN_ACTIVITY_TO_CLIENT_FAILURE";

export const GET_ASSIGN_BUILDING_ACTIVITIES_POPUP_DETAILS_REQUEST = "GET_ASSIGN_BUILDING_ACTIVITIES_POPUP_DETAILS_REQUEST";
export const GET_ASSIGN_BUILDING_ACTIVITIES_POPUP_DETAILS_SUCCESS = "GET_ASSIGN_BUILDING_ACTIVITIES_POPUP_DETAILS_SUCCESS";
export const GET_ASSIGN_BUILDING_ACTIVITIES_POPUP_DETAILS_FAILURE = "GET_ASSIGN_BUILDING_ACTIVITIES_POPUP_DETAILS_FAILURE";

export const GET_CREATE_SURVEY_POPUP_DETAILS_FOR_BUILDING_ACTIVITIES_REQUEST = "GET_CREATE_SURVEY_POPUP_DETAILS_FOR_BUILDING_ACTIVITIES_REQUEST";
export const GET_CREATE_SURVEY_POPUP_DETAILS_FOR_BUILDING_ACTIVITIES_SUCCESS = "GET_CREATE_SURVEY_POPUP_DETAILS_FOR_BUILDING_ACTIVITIES_SUCCESS";
export const GET_CREATE_SURVEY_POPUP_DETAILS_FOR_BUILDING_ACTIVITIES_FAILURE = "GET_CREATE_SURVEY_POPUP_DETAILS_FOR_BUILDING_ACTIVITIES_FAILURE";

export const ASSIGN_CLIENT_ACTIVITY_TO_BUILDING_REQUEST = "ASSIGN_CLIENT_ACTIVITY_TO_BUILDING_REQUEST";
export const ASSIGN_CLIENT_ACTIVITY_TO_BUILDING_SUCCESS = "ASSIGN_CLIENT_ACTIVITY_TO_BUILDING_SUCCESS";
export const ASSIGN_CLIENT_ACTIVITY_TO_BUILDING_FAILURE = "ASSIGN_CLIENT_ACTIVITY_TO_BUILDING_FAILURE";

export const GET_ASSIGN_DEEMING_AGENCY_POPUP_FOR_FREQUENCY_DETAILS_REQUEST = "GET_ASSIGN_DEEMING_AGENCY_POPUP_FOR_FREQUENCY_DETAILS_REQUEST";
export const GET_ASSIGN_DEEMING_AGENCY_POPUP_FOR_FREQUENCY_DETAILS_SUCCESS = "GET_ASSIGN_DEEMING_AGENCY_POPUP_FOR_FREQUENCY_DETAILS_SUCCESS";
export const GET_ASSIGN_DEEMING_AGENCY_POPUP_FOR_FREQUENCY_DETAILS_FAILURE = "GET_ASSIGN_DEEMING_AGENCY_POPUP_FOR_FREQUENCY_DETAILS_FAILURE";

export const ASSIGN_DEEMING_AGENCY_TO_FREQUENCY_REQUEST = "ASSIGN_DEEMING_AGENCY_TO_FREQUENCY_REQUEST";
export const ASSIGN_DEEMING_AGENCY_TO_FREQUENCY_SUCCESS = "ASSIGN_DEEMING_AGENCY_TO_FREQUENCY_SUCCESS";
export const ASSIGN_DEEMING_AGENCY_TO_FREQUENCY_FAILURE = "ASSIGN_DEEMING_AGENCY_TO_FREQUENCY_FAILURE";

export const GET_ASSIGN_FREQUENCY_POPUP_FOR_DEEMING_AGENCY_DETAILS_REQUEST = "GET_ASSIGN_FREQUENCY_POPUP_FOR_DEEMING_AGENCY_DETAILS_REQUEST";
export const GET_ASSIGN_FREQUENCY_POPUP_FOR_DEEMING_AGENCY_DETAILS_SUCCESS = "GET_ASSIGN_FREQUENCY_POPUP_FOR_DEEMING_AGENCY_DETAILS_SUCCESS";
export const GET_ASSIGN_FREQUENCY_POPUP_FOR_DEEMING_AGENCY_DETAILS_FAILURE = "GET_ASSIGN_FREQUENCY_POPUP_FOR_DEEMING_AGENCY_DETAILS_FAILURE";

export const ASSIGN_FREQUENCY_TO_DEEMING_AGENCY_REQUEST = "ASSIGN_FREQUENCY_TO_DEEMING_AGENCY_REQUEST";
export const ASSIGN_FREQUENCY_TO_DEEMING_AGENCY_SUCCESS = "ASSIGN_FREQUENCY_TO_DEEMING_AGENCY_SUCCESS";
export const ASSIGN_FREQUENCY_TO_DEEMING_AGENCY_FAILURE = "ASSIGN_FREQUENCY_TO_DEEMING_AGENCY_FAILURE";

export const UPDATE_ASSIGN_POPUP_API_TRIGGER_SUCCESS = "UPDATE_ASSIGN_POPUP_API_TRIGGER_SUCCESS";
export const UPDATE_ASSIGN_POPUP_API_TRIGGER_FAILURE = "UPDATE_ASSIGN_POPUP_API_TRIGGER_FAILURE";

export const UPDATE_FREQUENCY_DEEMING_AGENCY_API_TRIGGER_SUCCESS = "UPDATE_FREQUENCY_DEEMING_AGENCY_API_TRIGGER_SUCCESS";
export const UPDATE_FREQUENCY_DEEMING_AGENCY_API_TRIGGER_FAILURE = "UPDATE_FREQUENCY_DEEMING_AGENCY_API_TRIGGER_FAILURE";

export const GET_ASSIGN_USER_POPUP_FOR_SECTOR_DETAILS_REQUEST = "GET_ASSIGN_USER_POPUP_FOR_SECTOR_DETAILS_REQUEST";
export const GET_ASSIGN_USER_POPUP_FOR_SECTOR_DETAILS_SUCCESS = "GET_ASSIGN_USER_POPUP_FOR_SECTOR_DETAILS_SUCCESS";
export const GET_ASSIGN_USER_POPUP_FOR_SECTOR_DETAILS_FAILURE = "GET_ASSIGN_USER_POPUP_FOR_SECTOR_DETAILS_FAILURE";

export const ASSIGN_USER_TO_SECTOR_REQUEST = "ASSIGN_USER_TO_SECTOR_REQUEST";
export const ASSIGN_USER_TO_SECTOR_SUCCESS = "ASSIGN_USER_TO_SECTOR_SUCCESS";
export const ASSIGN_USER_TO_SECTOR_FAILURE = "ASSIGN_USER_TO_SECTOR_FAILURE";

export const GET_ASSIGN_USER_POPUP_FOR_CAMPUS_DETAILS_REQUEST = "GET_ASSIGN_USER_POPUP_FOR_CAMPUS_DETAILS_REQUEST";
export const GET_ASSIGN_USER_POPUP_FOR_CAMPUS_DETAILS_SUCCESS = "GET_ASSIGN_USER_POPUP_FOR_CAMPUS_DETAILS_SUCCESS";
export const GET_ASSIGN_USER_POPUP_FOR_CAMPUS_DETAILS_FAILURE = "GET_ASSIGN_USER_POPUP_FOR_CAMPUS_DETAILS_FAILURE";

export const ASSIGN_USER_TO_CAMPUS_REQUEST = "ASSIGN_USER_TO_CAMPUS_REQUEST";
export const ASSIGN_USER_TO_CAMPUS_SUCCESS = "ASSIGN_USER_TO_CAMPUS_SUCCESS";
export const ASSIGN_USER_TO_CAMPUS_FAILURE = "ASSIGN_USER_TO_CAMPUS_FAILURE";

export const GET_ASSIGN_USER_POPUP_FOR_BUILDING_DETAILS_REQUEST = "GET_ASSIGN_USER_POPUP_FOR_BUILDING_DETAILS_REQUEST";
export const GET_ASSIGN_USER_POPUP_FOR_BUILDING_DETAILS_SUCCESS = "GET_ASSIGN_USER_POPUP_FOR_BUILDING_DETAILS_SUCCESS";
export const GET_ASSIGN_USER_POPUP_FOR_BUILDING_DETAILS_FAILURE = "GET_ASSIGN_USER_POPUP_FOR_BUILDING_DETAILS_FAILURE";

export const ASSIGN_USER_TO_BUILDING_REQUEST = "ASSIGN_USER_TO_BUILDING_REQUEST";
export const ASSIGN_USER_TO_BUILDING_SUCCESS = "ASSIGN_USER_TO_BUILDING_SUCCESS";
export const ASSIGN_USER_TO_BUILDING_FAILURE = "ASSIGN_USER_TO_BUILDING_FAILURE";

export const GET_ASSIGN_BUILDING_LOGBOOK_TO_USER_POPUP_REQUEST = "GET_ASSIGN_BUILDING_LOGBOOK_TO_USER_POPUP_REQUEST";
export const GET_ASSIGN_BUILDING_LOGBOOK_TO_USER_POPUP_SUCCESS = "GET_ASSIGN_BUILDING_LOGBOOK_TO_USER_POPUP_SUCCESS";
export const GET_ASSIGN_BUILDING_LOGBOOK_TO_USER_POPUP_FAILURE = "GET_ASSIGN_BUILDING_LOGBOOK_TO_USER_POPUP_FAILURE";

export const ASSIGN_BUILDING_LOGBOOK_TO_USER_REQUEST = "ASSIGN_BUILDING_LOGBOOK_TO_USER_REQUEST";
export const ASSIGN_BUILDING_LOGBOOK_TO_USER_SUCCESS = "ASSIGN_BUILDING_LOGBOOK_TO_USER_SUCCESS";
export const ASSIGN_BUILDING_LOGBOOK_TO_USER_FAILURE = "ASSIGN_BUILDING_LOGBOOK_TO_USER_FAILURE";

export const GET_ASSIGN_BUILDING_TO_USER_POPUP_REQUEST = "GET_ASSIGN_BUILDING_TO_USER_POPUP_REQUEST";
export const GET_ASSIGN_BUILDING_TO_USER_POPUP_SUCCESS = "GET_ASSIGN_BUILDING_TO_USER_POPUP_SUCCESS";
export const GET_ASSIGN_BUILDING_TO_USER_POPUP_FAILURE = "GET_ASSIGN_BUILDING_TO_USER_POPUP_FAILURE";

export const ASSIGN_BUILDING_TO_USER_REQUEST = "ASSIGN_BUILDING_TO_USER_REQUEST";
export const ASSIGN_BUILDING_TO_USER_SUCCESS = "ASSIGN_BUILDING_TO_USER_SUCCESS";
export const ASSIGN_BUILDING_TO_USER_FAILURE = "ASSIGN_BUILDING_TO_USER_FAILURE";

export const GET_ASSIGN_CONSULTANCY_TO_LOGBOOK_POPUP_REQUEST = "GET_ASSIGN_CONSULTANCY_TO_LOGBOOK_POPUP_REQUEST";
export const GET_ASSIGN_CONSULTANCY_TO_LOGBOOK_POPUP_SUCCESS = "GET_ASSIGN_CONSULTANCY_TO_LOGBOOK_POPUP_SUCCESS";
export const GET_ASSIGN_CONSULTANCY_TO_LOGBOOK_POPUP_FAILURE = "GET_ASSIGN_CONSULTANCY_TO_LOGBOOK_POPUP_FAILURE";

export const ASSIGN_CONSULTANCY_TO_LOGBOOK_REQUEST = "ASSIGN_CONSULTANCY_TO_LOGBOOK_REQUEST";
export const ASSIGN_CONSULTANCY_TO_LOGBOOK_SUCCESS = "ASSIGN_CONSULTANCY_TO_LOGBOOK_SUCCESS";
export const ASSIGN_CONSULTANCY_TO_LOGBOOK_FAILURE = "ASSIGN_CONSULTANCY_TO_LOGBOOK_FAILURE";

export const GET_ASSIGN_CLIENT_TO_LOGBOOK_POPUP_REQUEST = "GET_ASSIGN_CLIENT_TO_LOGBOOK_POPUP_REQUEST";
export const GET_ASSIGN_CLIENT_TO_LOGBOOK_POPUP_SUCCESS = "GET_ASSIGN_CLIENT_TO_LOGBOOK_POPUP_SUCCESS";
export const GET_ASSIGN_CLIENT_TO_LOGBOOK_POPUP_FAILURE = "GET_ASSIGN_CLIENT_TO_LOGBOOK_POPUP_FAILURE";

export const ASSIGN_CLIENT_TO_LOGBOOK_REQUEST = "ASSIGN_CLIENT_TO_LOGBOOK_REQUEST";
export const ASSIGN_CLIENT_TO_LOGBOOK_SUCCESS = "ASSIGN_CLIENT_TO_LOGBOOK_SUCCESS";
export const ASSIGN_CLIENT_TO_LOGBOOK_FAILURE = "ASSIGN_CLIENT_TO_LOGBOOK_FAILURE";

export const GET_ASSIGN_BUILDING_TO_LOGBOOK_POPUP_REQUEST = "GET_ASSIGN_BUILDING_TO_LOGBOOK_POPUP_REQUEST";
export const GET_ASSIGN_BUILDING_TO_LOGBOOK_POPUP_SUCCESS = "GET_ASSIGN_BUILDING_TO_LOGBOOK_POPUP_SUCCESS";
export const GET_ASSIGN_BUILDING_TO_LOGBOOK_POPUP_FAILURE = "GET_ASSIGN_BUILDING_TO_LOGBOOK_POPUP_FAILURE";

export const ASSIGN_BUILDING_TO_LOGBOOK_REQUEST = "ASSIGN_BUILDING_TO_LOGBOOK_REQUEST";
export const ASSIGN_BUILDING_TO_LOGBOOK_SUCCESS = "ASSIGN_BUILDING_TO_LOGBOOK_SUCCESS";
export const ASSIGN_BUILDING_TO_LOGBOOK_FAILURE = "ASSIGN_BUILDING_TO_LOGBOOK_FAILURE";

export const GET_LOGBOOK_CREATE_SURVEY_POPUP_REQUEST = "GET_LOGBOOK_CREATE_SURVEY_POPUP_REQUEST";
export const GET_LOGBOOK_CREATE_SURVEY_POPUP_SUCCESS = "GET_LOGBOOK_CREATE_SURVEY_POPUP_SUCCESS";
export const GET_LOGBOOK_CREATE_SURVEY_POPUP_FAILURE = "GET_LOGBOOK_CREATE_SURVEY_POPUP_FAILURE";

export const CREATE_SURVEY_BUILDING_LOGBOOK_REQUEST = "CREATE_SURVEY_BUILDING_LOGBOOK_REQUEST";
export const CREATE_SURVEY_BUILDING_LOGBOOK_SUCCESS = "CREATE_SURVEY_BUILDING_LOGBOOK_SUCCESS";
export const CREATE_SURVEY_BUILDING_LOGBOOK_FAILURE = "CREATE_SURVEY_BUILDING_LOGBOOK_FAILURE";

export const GET_ASSIGN_CONSULTANCY_TO_ACTIVITY_POPUP_REQUEST = "GET_ASSIGN_CONSULTANCY_TO_ACTIVITY_POPUP_REQUEST";
export const GET_ASSIGN_CONSULTANCY_TO_ACTIVITY_POPUP_SUCCESS = "GET_ASSIGN_CONSULTANCY_TO_ACTIVITY_POPUP_SUCCESS";
export const GET_ASSIGN_CONSULTANCY_TO_ACTIVITY_POPUP_FAILURE = "GET_ASSIGN_CONSULTANCY_TO_ACTIVITY_POPUP_FAILURE";

export const ASSIGN_CONSULTANCY_TO_ACTIVITY_REQUEST = "ASSIGN_CONSULTANCY_TO_ACTIVITY_REQUEST";
export const ASSIGN_CONSULTANCY_TO_ACTIVITY_SUCCESS = "ASSIGN_CONSULTANCY_TO_ACTIVITY_SUCCESS";
export const ASSIGN_CONSULTANCY_TO_ACTIVITY_FAILURE = "ASSIGN_CONSULTANCY_TO_ACTIVITY_FAILURE";

export const GET_ASSIGN_CLIENT_TO_ACTIVITY_POPUP_REQUEST = "GET_ASSIGN_CLIENT_TO_ACTIVITY_POPUP_REQUEST";
export const GET_ASSIGN_CLIENT_TO_ACTIVITY_POPUP_SUCCESS = "GET_ASSIGN_CLIENT_TO_ACTIVITY_POPUP_SUCCESS";
export const GET_ASSIGN_CLIENT_TO_ACTIVITY_POPUP_FAILURE = "GET_ASSIGN_CLIENT_TO_ACTIVITY_POPUP_FAILURE";

export const ASSIGN_CLIENT_TO_ACTIVITY_REQUEST = "ASSIGN_CLIENT_TO_ACTIVITY_REQUEST";
export const ASSIGN_CLIENT_TO_ACTIVITY_SUCCESS = "ASSIGN_CLIENT_TO_ACTIVITY_SUCCESS";
export const ASSIGN_CLIENT_TO_ACTIVITY_FAILURE = "ASSIGN_CLIENT_TO_ACTIVITY_FAILURE";

export const GET_ASSIGN_BUILDING_TO_ACTIVITY_POPUP_REQUEST = "GET_ASSIGN_BUILDING_TO_ACTIVITY_POPUP_REQUEST";
export const GET_ASSIGN_BUILDING_TO_ACTIVITY_POPUP_SUCCESS = "GET_ASSIGN_BUILDING_TO_ACTIVITY_POPUP_SUCCESS";
export const GET_ASSIGN_BUILDING_TO_ACTIVITY_POPUP_FAILURE = "GET_ASSIGN_BUILDING_TO_ACTIVITY_POPUP_FAILURE";

export const ASSIGN_BUILDING_TO_ACTIVITY_REQUEST = "ASSIGN_BUILDING_TO_ACTIVITY_REQUEST";
export const ASSIGN_BUILDING_TO_ACTIVITY_SUCCESS = "ASSIGN_BUILDING_TO_ACTIVITY_SUCCESS";
export const ASSIGN_BUILDING_TO_ACTIVITY_FAILURE = "ASSIGN_BUILDING_TO_ACTIVITY_FAILURE";

export const GET_ACTIVITY_CREATE_SURVEY_POPUP_REQUEST = "GET_ACTIVITY_CREATE_SURVEY_POPUP_REQUEST";
export const GET_ACTIVITY_CREATE_SURVEY_POPUP_SUCCESS = "GET_ACTIVITY_CREATE_SURVEY_POPUP_SUCCESS";
export const GET_ACTIVITY_CREATE_SURVEY_POPUP_FAILURE = "GET_ACTIVITY_CREATE_SURVEY_POPUP_FAILURE";

export const CREATE_SURVEY_BUILDING_ACTIVITY_REQUEST = "CREATE_SURVEY_BUILDING_ACTIVITY_REQUEST";
export const CREATE_SURVEY_BUILDING_ACTIVITY_SUCCESS = "CREATE_SURVEY_BUILDING_ACTIVITY_SUCCESS";
export const CREATE_SURVEY_BUILDING_ACTIVITY_FAILURE = "CREATE_SURVEY_BUILDING_ACTIVITY_FAILURE";

export const GET_ASSIGN_PROCEDURE_TO_ACTIVITY_POPUP_REQUEST = "GET_ASSIGN_PROCEDURE_TO_ACTIVITY_POPUP_REQUEST";
export const GET_ASSIGN_PROCEDURE_TO_ACTIVITY_POPUP_SUCCESS = "GET_ASSIGN_PROCEDURE_TO_ACTIVITY_POPUP_SUCCESS";
export const GET_ASSIGN_PROCEDURE_TO_ACTIVITY_POPUP_FAILURE = "GET_ASSIGN_PROCEDURE_TO_ACTIVITY_POPUP_FAILURE";

export const ASSIGN_PROCEDURE_TO_ACTIVITY_REQUEST = "ASSIGN_PROCEDURE_TO_ACTIVITY_REQUEST";
export const ASSIGN_PROCEDURE_TO_ACTIVITY_SUCCESS = "ASSIGN_PROCEDURE_TO_ACTIVITY_SUCCESS";
export const ASSIGN_PROCEDURE_TO_ACTIVITY_FAILURE = "ASSIGN_PROCEDURE_TO_ACTIVITY_FAILURE";

export const GET_UNREAD_NOTIFICATIONS_REQUEST = "GET_UNREAD_NOTIFICATIONS_REQUEST";
export const GET_UNREAD_NOTIFICATIONS_SUCCESS = "GET_UNREAD_NOTIFICATIONS_SUCCESS";
export const GET_UNREAD_NOTIFICATIONS_FAILURE = "GET_UNREAD_NOTIFICATIONS_FAILURE";

export const READ_NOTIFICATION_REQUEST = "READ_NOTIFICATION_REQUEST";
export const READ_NOTIFICATION_SUCCESS = "READ_NOTIFICATION_SUCCESS";
export const READ_NOTIFICATION_FAILURE = "READ_NOTIFICATION_FAILURE";

export const DELETE_UNREAD_NOTIFICATIONS_REQUEST = "DELETE_UNREAD_NOTIFICATIONS_REQUEST";
export const DELETE_UNREAD_NOTIFICATIONS_SUCCESS = "DELETE_UNREAD_NOTIFICATIONS_SUCCESS";
export const DELETE_UNREAD_NOTIFICATIONS_FAILURE = "DELETE_UNREAD_NOTIFICATIONS_FAILURE";

export const GET_FILTER_DROPDOWN_DATA_REQUEST = "GET_FILTER_DROPDOWN_DATA_REQUEST";
export const GET_FILTER_DROPDOWN_DATA_SUCCESS = "GET_FILTER_DROPDOWN_DATA_SUCCESS";
export const GET_FILTER_DROPDOWN_DATA_FAILURE = "GET_FILTER_DROPDOWN_DATA_FAILURE";

export const GET_DEVICE_DOCUMENTS_REQUEST = "GET_DEVICE_DOCUMENTS_REQUEST";
export const GET_DEVICE_DOCUMENTS_SUCCESS = "GET_DEVICE_DOCUMENTS_SUCCESS";
export const GET_DEVICE_DOCUMENTS_FAILURE = "GET_DEVICE_DOCUMENTS_FAILURE";

export const SAVE_DEVICE_COUNT_REQUEST = "SAVE_DEVICE_COUNT_REQUEST";
export const SAVE_DEVICE_COUNT_SUCCESS = "SAVE_DEVICE_COUNT_SUCCESS";
export const SAVE_DEVICE_COUNT_FAILURE = "SAVE_DEVICE_COUNT_FAILURE";

export const SET_AUDIT_MODE_REQUEST = "SET_AUDIT_MODE_REQUEST";
export const SET_AUDIT_MODE_SUCCESS = "SET_AUDIT_MODE_SUCCESS";
export const SET_AUDIT_MODE_FAILURE = "SET_AUDIT_MODE_FAILURE";

export const SET_RESET_PASSWORD_REQUEST = "SET_RESET_PASSWORD_REQUEST";
export const SET_RESET_PASSWORD_SUCCESS = "SET_RESET_PASSWORD_SUCCESS";
export const SET_RESET_PASSWORD_FAILURE = "SET_RESET_PASSWORD_FAILURE";

export const GET_G_L_T_DETAILS_REQUEST = "GET_G_L_T_DETAILS_REQUEST";
export const GET_G_L_T_DETAILS_SUCCESS = "GET_G_L_T_DETAILS_SUCCESS";
export const GET_G_L_T_DETAILS_FAILURE = "GET_G_L_T_DETAILS_FAILURE";

export const GET_PREVIOUS_SURVEYS_REQUEST = "GET_PREVIOUS_SURVEYS_REQUEST";
export const GET_PREVIOUS_SURVEYS_SUCCESS = "GET_PREVIOUS_SURVEYS_SUCCESS";
export const GET_PREVIOUS_SURVEYS_FAILURE = "GET_PREVIOUS_SURVEYS_FAILURE";

export const GET_PREVIOUS_LOCATIONS_REQUEST = "GET_PREVIOUS_LOCATIONS_REQUEST";
export const GET_PREVIOUS_LOCATIONS_SUCCESS = "GET_PREVIOUS_LOCATIONS_SUCCESS";
export const GET_PREVIOUS_LOCATIONS_FAILURE = "GET_PREVIOUS_LOCATIONS_FAILURE";

export const GET_PREVIOUS_DAYS_REQUEST = "GET_PREVIOUS_DAYS_REQUEST";
export const GET_PREVIOUS_DAYS_SUCCESS = "GET_PREVIOUS_DAYS_SUCCESS";
export const GET_PREVIOUS_DAYS_FAILURE = "GET_PREVIOUS_DAYS_FAILURE";

export const CHECK_BUILDING_COUNT_REQUEST = "CHECK_BUILDING_COUNT_REQUEST";
export const CHECK_BUILDING_COUNT_SUCCESS = "CHECK_BUILDING_COUNT_SUCCESS";
export const CHECK_BUILDING_COUNT_FAILURE = "CHECK_BUILDING_COUNT_FAILURE";

export const FETCH_FORM_SETTINGS_REQUEST = "FETCH_FORM_SETTINGS_REQUEST";
export const FETCH_FORM_SETTINGS_SUCCESS = "FETCH_FORM_SETTINGS_SUCCESS";
export const FETCH_FORM_SETTINGS_FAILURE = "FETCH_FORM_SETTINGS_FAILURE";

export const DELETE_DEVICE_COUNT_REQUEST = "DELETE_DEVICE_COUNT_REQUEST";
export const DELETE_DEVICE_COUNT_SUCCESS = "DELETE_DEVICE_COUNT_SUCCESS";
export const DELETE_DEVICE_COUNT_FAILURE = "DELETE_DEVICE_COUNT_FAILURE";

export const GET_SCHEDULE_DATES_REQUEST = "GET_SCHEDULE_DATES_REQUEST";
export const GET_SCHEDULE_DATES_SUCCESS = "GET_SCHEDULE_DATES_SUCCESS";
export const GET_SCHEDULE_DATES_FAILURE = "GET_SCHEDULE_DATES_FAILURE";
