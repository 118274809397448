export const consultancyLogbookTableData = {
    keys: ["code", "name", "display_name", "color", "text_color", "line", "order", "is_active", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Logbook Code",
            class: "",
            searchKey: "consultancy_logbooks.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "code"
        },
        is_active: {
            isVisible: true,
            label: "Is Active?",
            class: "",
            searchKey: "consultancy_logbooks.is_active",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "is_active"
        },
        name: {
            isVisible: true,
            label: "Logbook Name",
            class: "reg-name",
            searchKey: "consultancy_logbooks.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "name"
        },
        display_name: {
            isVisible: true,
            label: "Display Name",
            class: "reg-name",
            searchKey: "consultancy_logbooks.display_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "display_name"
        },
        color: {
            isVisible: true,
            label: "Color",
            class: "",
            searchKey: "consultancy_logbooks.color",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "color"
        },
        text_color: {
            isVisible: true,
            label: "Text Color",
            class: "",
            searchKey: "consultancy_logbooks.text_color",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "text_color"
        },
        line: {
            isVisible: true,
            label: "Line",
            class: "",
            searchKey: "consultancy_logbooks.line",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "line"
        },
        order: {
            isVisible: true,
            label: "Order",
            class: "",
            searchKey: "consultancy_logbooks.order",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "order"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "consultancy_logbooks.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "consultancy_logbooks.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "consultancy_logbooks",
            commonSearchKey: "consultancy_logbooks",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};
