export const assetTableData = {
    keys: [
        "asset_id",
        "name",
        "asset_type",
        "image",
        "consultancy",
        "client",
        "sector",
        "campus",
        "building",
        "asset_condition",
        "floor",
        "room",
        "area_served",
        "make",
        "model_number",
        "serial_number",
        "size",
        "volts",
        "amps",
        "year_of_unit",
        "installed_year",
        "service_life",
        "useful_life_remaining",
        "crv",
        "notes",
        "device_count",
        "is_active",
        "created_at",
        "updated_at"
    ],
    config: {
        asset_id: {
            isVisible: true,
            label: "Asset Id",
            class: "",
            searchKey: "assets.asset_id",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "asset_id"
        },
        name: {
            isVisible: true,
            label: "Asset Name",
            class: "",
            searchKey: "assets.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "name"
        },
        asset_type: {
            isVisible: true,
            label: "Asset Type",
            class: "",
            searchKey: "assets.asset_type",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "asset_type"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "client-wid",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        sector: {
            isVisible: true,
            label: "Sector",
            class: "",
            searchKey: "sectors.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "sector",
            commonSearchKey: "sectors",
            commonSearchObjectKey: "name"
        },
        campus: {
            isVisible: true,
            label: "Campus",
            class: "",
            searchKey: "campus.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "campus",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "name"
        },
        building: {
            isVisible: true,
            label: "Building",
            class: "",
            searchKey: "buildings.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "name"
        },
        floor: {
            isVisible: true,
            label: "Floor",
            class: "",
            searchKey: "floors.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "floors",
            commonSearchKey: "floors",
            commonSearchObjectKey: "name"
        },
        room: {
            isVisible: true,
            label: "Room",
            class: "",
            searchKey: "assets.room",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "room"
        },
        image: {
            isVisible: false,
            label: "Image",
            class: "",
            searchKey: "assets.image",
            type: "image",
            hasWildCardSearch: false,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "image"
        },
        area_served: {
            isVisible: true,
            label: "Area Served",
            class: "",
            searchKey: "assets.area_served",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "area_served"
        },
        make: {
            isVisible: true,
            label: "Make",
            class: "",
            searchKey: "assets.make",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "make"
        },
        model_number: {
            isVisible: true,
            label: "Model Number",
            class: "",
            searchKey: "assets.model_number",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "model_number"
        },
        serial_number: {
            isVisible: true,
            label: "Serial Number",
            class: "",
            searchKey: "assets.serial_number",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "serial_number"
        },
        size: {
            isVisible: true,
            label: "Size",
            class: "",
            searchKey: "assets.size",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "size"
        },
        volts: {
            isVisible: true,
            label: "Volts",
            class: "",
            searchKey: "assets.volts",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "volts"
        },
        amps: {
            isVisible: true,
            label: "Amps",
            class: "",
            searchKey: "assets.amps",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "amps"
        },
        year_of_unit: {
            isVisible: true,
            label: "Year of Unit",
            class: "",
            searchKey: "assets.year_of_unit",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "year_of_unit"
        },
        installed_year: {
            isVisible: true,
            label: "Installed Year",
            class: "",
            searchKey: "assets.installed_year",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "installed_year"
        },
        service_life: {
            isVisible: true,
            label: "Service Life",
            class: "",
            searchKey: "assets.service_life",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "service_life"
        },
        useful_life_remaining: {
            isVisible: true,
            label: "Useful Life Remaining",
            class: "",
            searchKey: "assets.useful_life_remaining",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "useful_life_remaining"
        },
        crv: {
            isVisible: true,
            label: "CRV",
            class: "",
            searchKey: "assets.crv",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "crv"
        },
        asset_condition: {
            isVisible: true,
            label: "Asset Condition",
            class: "",
            searchKey: "asset_conditions.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "asset_conditions",
            commonSearchKey: "asset_conditions",
            commonSearchObjectKey: "name"
        },
        notes: {
            isVisible: true,
            label: "Notes",
            class: "",
            searchKey: "assets.notes",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "notes"
        },
        device_count: {
            isVisible: true,
            label: "Device Count",
            class: "",
            searchKey: "assets.device_count",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "device_count"
        },
        is_active: {
            isVisible: true,
            label: "Is Active",
            class: "",
            searchKey: "assets.is_active",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "is_active"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "assets.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "assets.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};
