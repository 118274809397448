export const GET_ALL_FORM_TYPES_REQUEST = "GET_ALL_FORM_TYPES_REQUEST";
export const GET_ALL_FORM_TYPES_SUCCESS = "GET_ALL_FORM_TYPES_SUCCESS";
export const GET_ALL_FORM_TYPES_FAILURE = "GET_ALL_FORM_TYPES_FAILURE";

export const UPDATE_EVENT_FORMS_REQUEST = "UPDATE_EVENT_FORMS_REQUEST";
export const UPDATE_EVENT_FORMS_SUCCESS = "UPDATE_EVENT_FORMS_SUCCESS";
export const UPDATE_EVENT_FORMS_FAILURE = "UPDATE_EVENT_FORMS_FAILURE";

export const FETCH_EVENT_FORM_DATA_REQUEST = "FETCH_EVENT_FORM_DATA_REQUEST";
export const FETCH_EVENT_FORM_DATA_SUCCESS = "FETCH_EVENT_FORM_DATA_SUCCESS";
export const FETCH_EVENT_FORM_DATA_FAILURE = "FETCH_EVENT_FORM_DATA_FAILURE";
