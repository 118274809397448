export const logbookTableData = {
    keys: [
        "code",
        "name",
        "logbook_type",
        "display_name",
        "has_asset",
        "color",
        "text_color",
        "line",
        "order",
        "is_active",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Logbook Code",
            class: "",
            searchKey: "logbooks.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "code"
        },
        is_active: {
            isVisible: true,
            label: "Is Active?",
            class: "",
            searchKey: "logbooks.is_active",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "is_active"
        },
        logbook_type: {
            isVisible: true,
            label: "Logbook Type",
            class: "",
            searchKey: "logbooks.logbook_type",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "logbook_type"
        },
        has_asset: {
            isVisible: true,
            label: "Has Asset?",
            class: "",
            searchKey: "logbooks.has_asset",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "has_asset"
        },
        name: {
            isVisible: true,
            label: "Logbook Name",
            class: "reg-name",
            searchKey: "logbooks.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "name"
        },
        display_name: {
            isVisible: true,
            label: "Display Name",
            class: "reg-name",
            searchKey: "logbooks.display_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "display_name"
        },
        color: {
            isVisible: true,
            label: "Color",
            class: "",
            searchKey: "logbooks.color",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "color"
        },
        text_color: {
            isVisible: true,
            label: "Text Color",
            class: "",
            searchKey: "logbooks.text_color",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "text_color"
        },
        line: {
            isVisible: true,
            label: "Line",
            class: "",
            searchKey: "logbooks.line",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "line"
        },
        order: {
            isVisible: true,
            label: "Order",
            class: "",
            searchKey: "logbooks.order",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "order"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "logbooks.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "logbooks.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "logbooks",
            commonSearchKey: "logbooks",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};
