import * as actionTypes from "./constants";

const initialState = {
    getDashboardDataResponse: {},
    getEventBreakdownChartByHierarchy: {}
};

const reducerResp = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DASHBOARD_DATA_REQUEST:
            return {
                ...state
            };
        case actionTypes.GET_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                getDashboardDataResponse: { success: true, ...action.response }
            };
        case actionTypes.GET_DASHBOARD_DATA_FAILURE:
            return {
                ...state,
                getDashboardDataResponse: { success: false, ...action.error }
            };
        case actionTypes.GET_EVENT_BREAKDOWN_BY_HIERARCHY_REQUEST:
            return {
                ...state,
                getEventBreakdownChartByHierarchy: {
                    ...state.getEventBreakdownChartByHierarchy,
                    [action.key]: { isLoading: true }
                }
            };
        case actionTypes.GET_EVENT_BREAKDOWN_BY_HIERARCHY_SUCCESS:
            return {
                ...state,
                getEventBreakdownChartByHierarchy: {
                    ...state.getEventBreakdownChartByHierarchy,
                    [action.key]: { isLoading: false, success: true, ...action.response }
                }
            };
        // case actionTypes.GET_EVENT_BREAKDOWN_BY_HIERARCHY_SUCCESS:
        //     return {
        //         ...state,
        //         getEventBreakdownChartByHierarchy: { isLoading: false, success: true, ...action.response }
        //     };
        case actionTypes.GET_EVENT_BREAKDOWN_BY_HIERARCHY_FAILURE:
            return {
                ...state,
                getEventBreakdownChartByHierarchy: {
                    ...state.getEventBreakdownChartByHierarchy,
                    [action.key]: { isLoading: false, success: false, ...action.error }
                }
            };

        default:
            return {
                ...state
            };
    }
};

export default reducerResp;
