export const GET_ACTIVITY_LIST_REQUEST = "GET_ACTIVITY_LIST_REQUEST";
export const GET_ACTIVITY_LIST_SUCCESS = "GET_ACTIVITY_LIST_SUCCESS";
export const GET_ACTIVITY_LIST_FAILURE = "GET_ACTIVITY_LIST_FAILURE";

export const ADD_ACTIVITY_REQUEST = "ADD_ACTIVITY_REQUEST";
export const ADD_ACTIVITY_SUCCESS = "ADD_ACTIVITY_SUCCESS";
export const ADD_ACTIVITY_FAILURE = "ADD_ACTIVITY_FAILURE";

export const DELETE_ACTIVITY_REQUEST = "DELETE_ACTIVITY_REQUEST";
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_FAILURE = "DELETE_ACTIVITY_FAILURE";

export const EDIT_ACTIVITY_REQUEST = "EDIT_ACTIVITY_REQUEST";
export const EDIT_ACTIVITY_SUCCESS = "EDIT_ACTIVITY_SUCCESS";
export const EDIT_ACTIVITY_FAILURE = "EDIT_ACTIVITY_FAILURE";

export const PUSH_ACTIVITY_REQUEST = "PUSH_ACTIVITY_REQUEST";
export const PUSH_ACTIVITY_SUCCESS = "PUSH_ACTIVITY_SUCCESS";
export const PUSH_ACTIVITY_FAILURE = "PUSH_ACTIVITY_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_ACTIVITY_BY_ID_REQUEST = "GET_ACTIVITY_BY_ID_REQUEST";
export const GET_ACTIVITY_BY_ID_SUCCESS = "GET_ACTIVITY_BY_ID_SUCCESS";
export const GET_ACTIVITY_BY_ID_FAILURE = "GET_ACTIVITY_BY_ID_FAILURE";

export const EXPORT_ACTIVITY_TABLE_REQUEST = "EXPORT_ACTIVITY_TABLE_REQUEST";
export const EXPORT_ACTIVITY_TABLE_SUCCESS = "EXPORT_ACTIVITY_TABLE_SUCCESS";
export const EXPORT_ACTIVITY_TABLE_FAILURE = "EXPORT_ACTIVITY_TABLE_FAILURE";

export const GET_ALL_ACTIVITY_LOG_REQUEST = "GET_ALL_ACTIVITY_LOG_REQUEST";
export const GET_ALL_ACTIVITY_LOG_SUCCESS = "GET_ALL_ACTIVITY_LOG_SUCCESS";
export const GET_ALL_ACTIVITY_LOG_FAILURE = "GET_ALL_ACTIVITY_LOG_FAILURE";

export const RESTORE_ACTIVITY_LOG_REQUEST = "RESTORE_ACTIVITY_LOG_REQUEST";
export const RESTORE_ACTIVITY_LOG_SUCCESS = "RESTORE_ACTIVITY_LOG_SUCCESS";
export const RESTORE_ACTIVITY_LOG_FAILURE = "RESTORE_ACTIVITY_LOG_FAILURE";

export const DELETE_ACTIVITY_LOG_REQUEST = "DELETE_ACTIVITY_LOG_REQUEST";
export const DELETE_ACTIVITY_LOG_SUCCESS = "DELETE_ACTIVITY_LOG_SUCCESS";
export const DELETE_ACTIVITY_LOG_FAILURE = "DELETE_ACTIVITY_LOG_FAILURE";

export const UPDATE_ACTIVITY_ENTITY_PARAMS_SUCCESS = "UPDATE_ACTIVITY_ENTITY_PARAMS_SUCCESS";
export const UPDATE_ACTIVITY_ENTITY_PARAMS_FAILURE = "UPDATE_ACTIVITY_ENTITY_PARAMS_FAILURE";

export const GET_ASSIGN_FORM_TO_ACTIVITY_POPUP_REQUEST = "GET_ASSIGN_FORM_TO_ACTIVITY_POPUP_REQUEST";
export const GET_ASSIGN_FORM_TO_ACTIVITY_POPUP_SUCCESS = "GET_ASSIGN_FORM_TO_ACTIVITY_POPUP_SUCCESS";
export const GET_ASSIGN_FORM_TO_ACTIVITY_POPUP_FAILURE = "GET_ASSIGN_FORM_TO_ACTIVITY_POPUP_FAILURE";

export const ASSIGN_FORM_TO_ACTIVITY_REQUEST = "ASSIGN_FORM_TO_ACTIVITY_REQUEST";
export const ASSIGN_FORM_TO_ACTIVITY_SUCCESS = "ASSIGN_FORM_TO_ACTIVITY_SUCCESS";
export const ASSIGN_FORM_TO_ACTIVITY_FAILURE = "ASSIGN_FORM_TO_ACTIVITY_FAILURE";

export const GET_LINKED_ACTIVITY_LIST_REQUEST = "GET_LINKED_ACTIVITY_LIST_REQUEST";
export const GET_LINKED_ACTIVITY_LIST_SUCCESS = "GET_LINKED_ACTIVITY_LIST_SUCCESS";
export const GET_LINKED_ACTIVITY_LIST_FAILURE = "GET_LINKED_ACTIVITY_LIST_FAILURE";

export const GET_CATEGORY_DROPDOWN_REQUEST = "GET_CATEGORY_DROPDOWN_REQUEST";
export const GET_CATEGORY_DROPDOWN_SUCCESS = "GET_CATEGORY_DROPDOWN_SUCCESS";
export const GET_CATEGORY_DROPDOWN_FAILURE = "GET_CATEGORY_DROPDOWN_FAILURE";
