export const GET_DASHBOARD_DATA_REQUEST = "GET_DASHBOARD_DATA_REQUEST";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAILURE = "GET_DASHBOARD_DATA_FAILURE";

export const GET_CONSULTANCY_MASTER_FILTER_REQUEST = "GET_CONSULTANCY_MASTER_FILTER_REQUEST";
export const GET_CONSULTANCY_MASTER_FILTER_SUCCESS = "GET_CONSULTANCY_MASTER_FILTER_SUCCESS";
export const GET_CONSULTANCY_MASTER_FILTER_FAILURE = "GET_CONSULTANCY_MASTER_FILTER_FAILURE";

export const GET_EVENT_BREAKDOWN_BY_HIERARCHY_REQUEST = "GET_EVENT_BREAKDOWN_BY_HIERARCHY_REQUEST";
export const GET_EVENT_BREAKDOWN_BY_HIERARCHY_SUCCESS = "GET_EVENT_BREAKDOWN_BY_HIERARCHY_SUCCESS";
export const GET_EVENT_BREAKDOWN_BY_HIERARCHY_FAILURE = "GET_EVENT_BREAKDOWN_BY_HIERARCHY_FAILURE";