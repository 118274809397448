export const campusTableData = {
    keys: ["code", "name", "display_name", "consultancy", "client", "sector", "comments", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Campus Code",
            class: "",
            searchKey: "campus.code",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campuses",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Campus Name",
            class: "reg-name",
            searchKey: "campus.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campuses",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "name"
        },
        display_name: {
            isVisible: true,
            label: "Display Name",
            class: "reg-name",
            searchKey: "campus.display_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campuses",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "client-wid",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        sector: {
            isVisible: true,
            label: "Sector",
            class: "",
            searchKey: "sectors.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "sector",
            commonSearchKey: "sectors",
            commonSearchObjectKey: "name"
        },
        users: {
            isVisible: true,
            label: "Consultancy Users",
            class: "",
            searchKey: "users.name",
            type: "array",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "assigned_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "name"
        },
        client_users: {
            isVisible: true,
            label: "Client Users",
            class: "",
            searchKey: "client_users.name",
            type: "array",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "client_users",
            commonSearchKey: "client_users",
            commonSearchObjectKey: "name"
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "campus.comments",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campuses",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "comments"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "campus.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campuses",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "campus.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "campuses",
            commonSearchKey: "campuses",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};
