export const GET_CLIENT_ACTIVITY_LIST_REQUEST = "GET_CLIENT_ACTIVITY_LIST_REQUEST";
export const GET_CLIENT_ACTIVITY_LIST_SUCCESS = "GET_CLIENT_ACTIVITY_LIST_SUCCESS";
export const GET_CLIENT_ACTIVITY_LIST_FAILURE = "GET_CLIENT_ACTIVITY_LIST_FAILURE";

export const ADD_CLIENT_ACTIVITY_REQUEST = "ADD_CLIENT_ACTIVITY_REQUEST";
export const ADD_CLIENT_ACTIVITY_SUCCESS = "ADD_CLIENT_ACTIVITY_SUCCESS";
export const ADD_CLIENT_ACTIVITY_FAILURE = "ADD_CLIENT_ACTIVITY_FAILURE";

export const DELETE_CLIENT_ACTIVITY_REQUEST = "DELETE_CLIENT_ACTIVITY_REQUEST";
export const DELETE_CLIENT_ACTIVITY_SUCCESS = "DELETE_CLIENT_ACTIVITY_SUCCESS";
export const DELETE_CLIENT_ACTIVITY_FAILURE = "DELETE_CLIENT_ACTIVITY_FAILURE";

export const EDIT_CLIENT_ACTIVITY_REQUEST = "EDIT_CLIENT_ACTIVITY_REQUEST";
export const EDIT_CLIENT_ACTIVITY_SUCCESS = "EDIT_CLIENT_ACTIVITY_SUCCESS";
export const EDIT_CLIENT_ACTIVITY_FAILURE = "EDIT_CLIENT_ACTIVITY_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST"
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS"
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE"

export const GET_CLIENT_ACTIVITY_BY_ID_REQUEST = "GET_CLIENT_ACTIVITY_BY_ID_REQUEST"
export const GET_CLIENT_ACTIVITY_BY_ID_SUCCESS = "GET_CLIENT_ACTIVITY_BY_ID_SUCCESS"
export const GET_CLIENT_ACTIVITY_BY_ID_FAILURE = "GET_CLIENT_ACTIVITY_BY_ID_FAILURE"

export const EXPORT_CLIENT_ACTIVITY_TABLE_REQUEST = "EXPORT_CLIENT_ACTIVITY_TABLE_REQUEST"
export const EXPORT_CLIENT_ACTIVITY_TABLE_SUCCESS = "EXPORT_CLIENT_ACTIVITY_TABLE_SUCCESS"
export const EXPORT_CLIENT_ACTIVITY_TABLE_FAILURE = "EXPORT_CLIENT_ACTIVITY_TABLE_FAILURE"

export const GET_ALL_CLIENT_ACTIVITY_LOG_REQUEST = "GET_ALL_CLIENT_ACTIVITY_LOG_REQUEST";
export const GET_ALL_CLIENT_ACTIVITY_LOG_SUCCESS = "GET_ALL_CLIENT_ACTIVITY_LOG_SUCCESS";
export const GET_ALL_CLIENT_ACTIVITY_LOG_FAILURE = "GET_ALL_CLIENT_ACTIVITY_LOG_FAILURE";

export const RESTORE_CLIENT_ACTIVITY_LOG_REQUEST = "RESTORE_CLIENT_ACTIVITY_LOG_REQUEST";
export const RESTORE_CLIENT_ACTIVITY_LOG_SUCCESS = "RESTORE_CLIENT_ACTIVITY_LOG_SUCCESS";
export const RESTORE_CLIENT_ACTIVITY_LOG_FAILURE = "RESTORE_CLIENT_ACTIVITY_LOG_FAILURE";

export const DELETE_CLIENT_ACTIVITY_LOG_REQUEST = "DELETE_CLIENT_ACTIVITY_LOG_REQUEST";
export const DELETE_CLIENT_ACTIVITY_LOG_SUCCESS = "DELETE_CLIENT_ACTIVITY_LOG_SUCCESS";
export const DELETE_CLIENT_ACTIVITY_LOG_FAILURE = "DELETE_CLIENT_ACTIVITY_LOG_FAILURE";

export const UPDATE_CLIENT_ACTIVITY_ENTITY_PARAMS_SUCCESS ="UPDATE_CLIENT_ACTIVITY_ENTITY_PARAMS_SUCCESS";
export const UPDATE_CLIENT_ACTIVITY_ENTITY_PARAMS_FAILURE ="UPDATE_CLIENT_ACTIVITY_ENTITY_PARAMS_FAILURE";

export const EDIT_CLIENT_SHIFT_ACTIVITY_REQUEST = "EDIT_CLIENT_SHIFT_ACTIVITY_REQUEST";
export const EDIT_CLIENT_SHIFT_ACTIVITY_SUCCESS = "EDIT_CLIENT_SHIFT_ACTIVITY_SUCCESS";
export const EDIT_CLIENT_SHIFT_ACTIVITY_FAILURE = "EDIT_CLIENT_SHIFT_ACTIVITY_FAILURE";