export const smartReportTableData = {
    keys: [
        "building_logbook",
        "client",
        "sector",
        "campus",
        "building",
        "parent",
        "asset",
        "edit_form",
        "year",
        "deeming_agency",
        "activity_description",
        "code_reference",
        "next_due_date",
        "actual_date",
        "threshold_start",
        "threshold_end",
        "test_frequency",
        "standard",
        "date_last_performed",
        "status",
        "performed_by",
        "default_total_devices",
        "total_devices",
        "number_pass",
        "number_fail",
        "failure_percentage",
        "number_corrected",
        "ilsm",
        "corrected_date",
        "comments",
        "ilsm_comments",
        "device_comments",
        "total_device_updated",
        "risk_assessment",
        "risk_assessment_comments",
        "failure_comments",
        "thirty_min_run",
        "ten_sec_transfer",
        "meet_30_percent_load",
        "all_else_passed",
        "ats_used_to_start_test"
    ],
    config: {
        date_last_performed: {
            isVisible: true,
            label: "Last Performed",
            class: "",
            searchKey: "schedules.date_last_performed",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        deeming_agency: {
            isVisible: true,
            label: "Deeming Agency",
            class: "",
            searchKey: "deeming_agencies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "deeming_agency",
            commonSearchKey: "deeming_agencies",
            commonSearchObjectKey: "name"
        },
        standard: {
            isVisible: true,
            label: "Standard",
            class: "",
            searchKey: "schedules.standard",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        asset: {
            isVisible: true,
            label: "Asset",
            class: "",
            searchKey: "assets.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "asset",
            commonSearchKey: "assets",
            commonSearchObjectKey: "name"
        },
        edit_form: {
            isVisible: true,
            label: "Event Form",
            class: "",
            searchKey: "schedules.edit_form",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        parent: {
            isVisible: true,
            label: "Parent",
            class: "",
            searchKey: "schedules.parent",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        test_frequency: {
            isVisible: true,
            label: "Test Frequency",
            class: "",
            searchKey: "schedules.test_frequency",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        building_logbook: {
            isVisible: true,
            label: "Logbook",
            class: "",
            searchKey: "building_logbooks.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        sector: {
            isVisible: true,
            label: "Sector",
            class: "",
            searchKey: "sectors.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        campus: {
            isVisible: true,
            label: "Campus",
            class: "",
            searchKey: "campus.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        building: {
            isVisible: true,
            label: "Building",
            class: "",
            searchKey: "buildings.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        threshold_start: {
            isVisible: true,
            label: "Threshold Start",
            class: "",
            searchKey: "survey_dates.date - schedules.completion_threshold",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        threshold_end: {
            isVisible: true,
            label: "Threshold End",
            class: "",
            searchKey: "survey_dates.date + schedules.completion_threshold",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        year: {
            isVisible: true,
            label: "Survey Year",
            class: "",
            searchKey: "schedules.year",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        activity_description: {
            isVisible: true,
            label: "Activity",
            class: "",
            searchKey: "schedules.activity_description",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        code_reference: {
            isVisible: true,
            label: "Code Reference",
            class: "",
            searchKey: "schedules.code_reference",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        next_due_date: {
            isVisible: true,
            label: "Due Date",
            class: "",
            searchKey: "survey_dates.date",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        actual_date: {
            isVisible: true,
            label: "Actual Date",
            class: "",
            searchKey: "surveys.actual_date",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        status: {
            isVisible: true,
            label: "Status",
            class: "",
            searchKey: "survey_dates.status",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        performed_by: {
            isVisible: true,
            label: "Performed By",
            class: "",
            searchKey: "surveys.performed_by",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        total_devices: {
            isVisible: true,
            label: "Total Devices",
            class: "",
            searchKey: "surveys.total_devices",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        default_total_devices: {
            isVisible: true,
            label: "Master Inventory  Total Devices",
            class: "",
            searchKey: "schedules.default_total_devices",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        number_pass: {
            isVisible: true,
            label: "Passed",
            class: "",
            searchKey: "surveys.number_pass",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        number_fail: {
            isVisible: true,
            label: "Failed",
            class: "",
            searchKey: "surveys.number_fail",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        failure_percentage: {
            isVisible: true,
            label: "Failure Percentage",
            class: "",
            searchKey: "surveys.failure_percentage",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        number_corrected: {
            isVisible: true,
            label: "Corrected",
            class: "",
            searchKey: "surveys.number_corrected",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        ilsm: {
            isVisible: true,
            label: "Ilsm",
            class: "",
            searchKey: "surveys.ilsm",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        tw_enabled: {
            isVisible: true,
            label: "TW Enabled",
            class: "",
            searchKey: "schedules.tw_enabled",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        corrected_date: {
            isVisible: true,
            label: "Corrected Date",
            class: "",
            searchKey: "surveys.corrected_date",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "surveys.comments",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        ilsm_comments: {
            isVisible: true,
            label: "ILSM Comments",
            class: "",
            searchKey: "surveys.ilsm_comments",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        device_comments: {
            isVisible: true,
            label: "Device Comments",
            class: "",
            searchKey: "surveys.device_comments",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        total_device_updated: {
            isVisible: true,
            label: "Total Device Updated",
            class: "",
            searchKey: "surveys.total_device_updated",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        risk_assessment: {
            isVisible: true,
            label: "Risk Assessment",
            class: "",
            searchKey: "surveys.risk_assessment",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        risk_assessment_comments: {
            isVisible: true,
            label: "Risk Assessment Comments",
            class: "",
            searchKey: "surveys.risk_assessment_comments",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        failure_comments: {
            isVisible: true,
            label: "Failure Comments",
            class: "",
            searchKey: "surveys.failure_comments",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        thirty_min_run: {
            isVisible: true,
            label: "Thirty Min Run",
            class: "",
            searchKey: "surveys.thirty_min_run",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        ten_sec_transfer: {
            isVisible: true,
            label: "Ten Sec Transfer",
            class: "",
            searchKey: "surveys.ten_sec_transfer",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        meet_30_percent_load: {
            isVisible: true,
            label: "Meet 30 Percent Load",
            class: "",
            searchKey: "surveys.meet_30_percent_load",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        all_else_passed: {
            isVisible: true,
            label: "All Else Passed",
            class: "",
            searchKey: "surveys.all_else_passed",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        ats_used_to_start_test: {
            isVisible: true,
            label: "Ats Used To Start Test",
            class: "",
            searchKey: "surveys.ats_used_to_start_test",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        }
    },
    data: []
};
