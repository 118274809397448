export const activityCalenderTableCalenderData = {
    keys: [
        "asset",
        "date_last_performed",
        "standard",
        "activity_description",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ],
    config: {
        date_last_performed: {
            isVisible: false,
            label: "Last Performed",
            class: "l-perform",
            searchKey: "schedules.date_last_performed",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "schedules",
            commonSearchKey: "schedules",
            commonSearchObjectKey: "date_last_performed",
            item: 0
        },
        asset: {
            isVisible: true,
            label: "Asset",
            class: "",
            searchKey: "assets.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "name",
            item: 1
        },
        standard: {
            isVisible: true,
            label: "Std",
            class: "ref",
            searchKey: "schedules.standard",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "schedules",
            commonSearchKey: "schedules",
            commonSearchObjectKey: "standard",
            item: 2
        },
        activity_description: {
            isVisible: true,
            label: "Activity",
            class: "activity",
            searchKey: "schedules.activity_description",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 3
        },
        Jan: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Jan",
            item: 4
        },
        Feb: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Feb",
            item: 5
        },
        Mar: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Mar",
            item: 6
        },
        Apr: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Apr",
            item: 7
        },
        May: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "May",
            item: 8
        },
        Jun: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Jun",
            item: 9
        },
        Jul: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Jul",
            item: 10
        },
        Aug: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Aug",
            item: 11
        },
        Sep: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Sep",
            item: 12
        },
        Oct: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Oct",
            item: 13
        },
        Nov: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Nov",
            item: 14
        },
        Dec: {
            isVisible: false,
            label: "",
            class: "",
            searchKey: "",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            isMonth: true,
            monthSearchKey: "Dec",
            item: 15
        }
    },
    data: []
};

export const activityCalenderTableData = {
    keys: [
        "sl_no",
        "display_order",
        "deeming_agency",
        "year",
        "standard",
        "client",
        "sector",
        "campus",
        "building",
        "activity_description",
        "default_total_devices",
        "test_frequency",
        "code_reference",
        "procedure_id",
        "date_last_performed",
        "next_due_date"
    ],
    config: {
        display_order: {
            isVisible: false,
            label: "DO",
            class: "ref",
            searchKey: "schedules.display_order",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 0
        },
        deeming_agency: {
            isVisible: true,
            label: "DA",
            class: "ref",
            searchKey: "deeming_agencies.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "deeming_agency",
            commonSearchKey: "deeming_agencies",
            commonSearchObjectKey: "name",
            item: 1
        },
        year: {
            isVisible: true,
            label: "Year",
            class: "year",
            searchKey: "schedules.year",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 2
        },
        standard: {
            isVisible: true,
            label: "Std",
            class: "ref",
            searchKey: "schedules.standard",
            type: "window",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 3
        },
        sector: {
            isVisible: true,
            label: "Sector",
            class: "region",
            searchKey: "sectors.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 4
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "region",
            searchKey: "clients.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 5
        },
        campus: {
            isVisible: true,
            label: "Campus",
            class: "site",
            searchKey: "campus.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 6
        },
        building: {
            isVisible: true,
            label: "Building",
            class: "build",
            searchKey: "buildings.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 7
        },
        activity_description: {
            isVisible: true,
            label: "Activity",
            class: "activity",
            searchKey: "schedules.activity_description",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 8
        },
        default_total_devices: {
            isVisible: true,
            label: "DC",
            class: "d-count",
            searchKey: "schedules.default_total_devices",
            type: "number",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 9
        },
        test_frequency: {
            isVisible: true,
            label: "Test Frequecy",
            class: "t-freq",
            searchKey: "schedules.test_frequency",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 10
        },
        code_reference: {
            isVisible: true,
            label: "Code Reference",
            class: "code-ref",
            searchKey: "schedules.code_reference",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 11
        },
        procedure_id: {
            isVisible: false,
            label: "Procedure Id",
            class: "p-id",
            searchKey: "schedules.procedure_id",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 12
        },
        date_last_performed: {
            isVisible: true,
            label: "Last Performed",
            class: "l-perform",
            searchKey: "schedules.date_last_performed",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 13
        },
        next_due_date: {
            isVisible: true,
            label: "Next Due Date",
            class: "n-due",
            searchKey: "schedules.next_due_date",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 14
        },
        sl_no: {
            isVisible: true,
            label: "Sl No",
            class: "",
            searchKey: "",
            type: "number",
            hasWildCardSearch: false,
            hasSort: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            item: 15
        }
    },
    data: []
};
